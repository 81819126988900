import { env } from "../../env";
import axiosAdapter from "../../utils";

export const gitStatus = async (data) => {
  let response = await axiosAdapter(
    "POST",
    env.REACT_APP_URL + "debug/gitStatus",
    data,
  );
  return response.data;
};

export const getCurrentBranch = async (data) => {
  let response = await axiosAdapter(
    "POST",
    env.REACT_APP_URL + "debug/getCurrentBranch",
    data,
  );
  return response.data;
};
