import styled from "styled-components";
import GenericModal from "../../components/common/_genericModal";
import CustomRow from "../../components/common/_customRow";
import Column from "../../components/common/_customColumn";
import { useState } from "react";
import ArrowDownIcon from "../../assets/images/down_icon.svg";
import CheckIcon from "../../assets/images/check_circle_FILL0_wght400_GRAD0_opsz24 1.svg";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import { useContext } from "react";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { ConnectorsContext } from "../WorkSpaceConnectors/WorkSpaceConnectorsMainPage";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../../components/common/_buttonNewOne";
import { applicationIconMapper } from "../../components/common/_applicationIconMapper";

const MAX_CHARACTERS = 130;

const Text = styled.p`
  font-family: Articulat CF Medium;
  margin: 0;
  opacity: 0.7;
  color: #ececec;
`;

const ModalTextfield = styled.input`
  padding: 0.7rem;
  outline: none;
  background: inherit;
  width: 100%;
  border-radius: 0.55rem;
  border: 0.138rem solid #b0b0b0;
  color: #ececec;
  font-size: 0.972rem;
  font-family: Articulat CF Medium;
  &::placeholder {
    font-family: Articulat CF Medium;
    color: #b0b0b0;
    font-size: 0.972rem;
  }
`;

const DescriptionContainer = styled.div`
  position: relative;
  font-family: "Articulat CF Medium";
`;

const CharacterCount = styled.span`
  position: absolute;
  right: 0.417rem;
  bottom: 0.7rem;
  font-size: 0.84rem;
  color: #b0b0b0;
`;

const ModalTextArea = styled.textarea`
  width: 100%;
  padding: 0.7rem;
  outline: none;
  background: inherit;
  resize: none;
  border-radius: 0.55rem;
  border: 0.138rem solid #b0b0b0;
  color: #ececec;
  font-size: 0.972rem;
  font-family: Articulat CF Medium;

  &::placeholder {
    font-family: Articulat CF Medium;
    color: #b0b0b0;
    font-size: 0.972rem;
  }
`;

const VersionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 0.55rem;
  border: 0.139rem solid #b0b0b0;
  opacity: 0.5;
  padding: 0.7rem;
`;

const LogoBackground = styled.div`
  height: 3.89rem;
  width: 3.89rem;
  border-radius: 0.84rem;
  background: ${({ selected }) =>
    selected ? "rgba(71, 204, 214, 0.4)" : "#777777"};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

const StyledIcon = styled.img`
  height: 2.916rem;
  width: 2.916rem;
`;

const CheckMark = styled.img`
  position: absolute;
  bottom: 2.77rem;
  left: 2.77rem;
  width: 1.67rem;
  height: 1.67rem;
  display: ${({ selected }) => (selected ? "block" : "none")};
`;

const CustomConnectorEditModal = ({
  isOpen,
  onClose,
  customConnectorName,
  customConnectorId,
  customConnectorDescription,
  customConnectorVersion,
  customConnectorIconFile,
}) => {
  const { showMessage } = useContext(SnackbarContext);
  const { refetchCustomConnectors } = useContext(ConnectorsContext);

  const [connectorName, setConnectorName] = useState(customConnectorName);
  const [connectorDescription, setConnectorDescription] = useState(
    customConnectorDescription,
  );
  const [selectedIcon, setSelectedIcon] = useState(
    customConnectorIconFile || "ConversionPath.svg",
  );
  const [isLoading, setLoading] = useState(false);

  const handleDescriptionChange = (e) => {
    if (e.target.value.length <= MAX_CHARACTERS) {
      setConnectorDescription(e.target.value);
    }
  };

  const handleIconClick = (iconFile) => {
    setSelectedIcon(iconFile);
  };

  const handleEditClick = async () => {
    const payload = {
      customConnectorId,
      customConnectorVersion,
      connectorName,
      description: connectorDescription,
      icon_file: selectedIcon,
      workspaceId: parseInt(localStorage.getItem("selectedWorkspaceId") || 0),
    };
    setLoading(true);
    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/updateCustomConnector",
        JSON.stringify(payload),
      );
      showMessage("Successfully edited the Custom Connector", "success");
      refetchCustomConnectors();
      setLoading(false);
      onClose();
    } catch (err) {
      console.log("Error: updateCustomConnector", err);
      showMessage("Unable to edit the Custom Connector");
      setLoading(false);
    }
  };

  return (
    <GenericModal
      title={`Do you want to Edit ${customConnectorName} ?`}
      show={isOpen}
      onClose={onClose}
      maxHeight="80vh"
    >
      <CustomRow>
        <Column style={{ marginBottom: "2.25rem" }}>
          <Text
            style={{
              fontSize: "1.25rem",
              lineHeight: "1.5rem",
              letterSpacing: "0px",
            }}
          >
            If you choose to edit the Connector Details and save it, everyone in
            your team will see the new details and will replace the name
            wherever this connector is used.
          </Text>
        </Column>
        <Column xs={12} lg={5.85} style={{ marginBottom: "1.112rem" }}>
          <ModalTextfield
            value={connectorName}
            placeholder="Enter Connector Name"
            onChange={(e) => setConnectorName(e.target.value)}
          />
        </Column>
        <Column xs={12} lg={5.85} style={{ marginBottom: "1.112rem" }}>
          <VersionContainer>
            <Text style={{ fontSize: "0.972rem" }}>
              {customConnectorVersion}
            </Text>
            <img
              src={ArrowDownIcon}
              alt="Arrow Down"
              style={{ height: "1.4rem", width: "1.4rem" }}
            />
          </VersionContainer>
        </Column>
        <Column style={{ marginBottom: "1.67rem" }}>
          <DescriptionContainer>
            <ModalTextArea
              placeholder="Describe Your Application"
              value={connectorDescription}
              onChange={handleDescriptionChange}
              rows={3}
            />
            <CharacterCount>
              {connectorDescription.length}/{MAX_CHARACTERS}
            </CharacterCount>
          </DescriptionContainer>
        </Column>
        <Column style={{ marginBottom: "2.084rem" }}>
          <CustomRow style={{ gap: "1.112rem", justifyContent: "flex-start" }}>
            <Column lg={2.4} xl={2.4} xxl={2.4}>
              <Text
                style={{
                  color: "#FAFAFA",
                  fontSize: "1.25rem",
                  lineHeight: "1.55rem",
                  letterSpacing: "0px",
                }}
              >
                Choose an icon to display
              </Text>
            </Column>
            {Object.entries(applicationIconMapper).map(
              ([iconName, iconFile]) => (
                <Column
                  xs={5}
                  sm={2.5}
                  md={2}
                  lg={1.1}
                  xl={1.1}
                  xxl={1.1}
                  key={iconName}
                >
                  <LogoBackground
                    onClick={() => handleIconClick(iconName)}
                    selected={selectedIcon === iconName}
                  >
                    <StyledIcon
                      src={iconFile}
                      alt={iconName}
                      selected={selectedIcon === iconName}
                    />
                    <CheckMark
                      src={CheckIcon}
                      selected={selectedIcon === iconName}
                    />
                  </LogoBackground>
                </Column>
              ),
            )}
          </CustomRow>
        </Column>
        <Column xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
          <StyledButtonsecondary
            style={{ marginRight: "1.112rem", minWidth: "14.16rem" }}
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </StyledButtonsecondary>
          <StyledButtonPrimary
            style={{
              minWidth: "14.16rem",
            }}
            disabled={isLoading}
            onClick={handleEditClick}
          >
            Edit Connector
          </StyledButtonPrimary>
        </Column>
      </CustomRow>
    </GenericModal>
  );
};

export default CustomConnectorEditModal;
