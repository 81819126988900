import React, { useContext, useState } from "react";
import FlexContainer from "../common/_flexContainer";
import styled from "styled-components";
import Text from "../common/_text";
import { AuthContext } from "../../context/AuthContext";
import { ReactComponent as OpenIcon } from "../../assets/images/select_open_icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/select_close_icon.svg";
import Dropdown from "../common/_dropdown";
import { remToPx } from "../../utils";
import LoginAvatarDropdownContent from "./LoginAvatarDropdownContent";

const FlexContainerStyled = styled(FlexContainer)`
  cursor: pointer;
`;
const Chip = styled(FlexContainer)`
  place-content: center;
  width: 2.222rem;
  height: 2.222rem;
  background: #f6f6f6;
  border-radius: 100%;
`;
const OpenIconStyled = styled(OpenIcon)`
  width: 1.25rem;
  height: 1.25rem;
`;
const CloseIconStyled = styled(CloseIcon)`
  width: 1.25rem;
  height: 1.25rem;
`;
const dropdownOffset = remToPx(0.9);

const LoginAvatar = () => {
  const { userFullName } = useContext(AuthContext);
  const [loginAvatarDropdownOpen, setLoginAvatarDropdownOpen] = useState(false);

  return (
    <Dropdown
      open={loginAvatarDropdownOpen}
      onOpenChange={setLoginAvatarDropdownOpen}
      offsetValue={dropdownOffset}
      placement="bottom-end"
      content={<LoginAvatarDropdownContent />}
    >
      <FlexContainerStyled
        $gap="0.277rem"
        onClick={() => setLoginAvatarDropdownOpen(!loginAvatarDropdownOpen)}
      >
        <Chip>
          <Text $fontWeight="bold" $fontSize="1.25rem" $color="#0D0D0D">
            {userFullName.substring(0, 1).toUpperCase()}
          </Text>
        </Chip>
        {loginAvatarDropdownOpen ? <OpenIconStyled /> : <CloseIconStyled />}
      </FlexContainerStyled>
    </Dropdown>
  );
};

export default LoginAvatar;
