import { getSmoothStepPath, EdgeLabelRenderer, BaseEdge } from "reactflow";
import { ReactComponent as RightArrowIcon } from "../../../assets/images/pipeline_right_arrow_icon.svg";
import styled from "styled-components";

const StyledRightArrowIcon = styled(RightArrowIcon)`
  height: 10px;
  width: 10px;
  & path {
    stroke: ${(props) => props.edgeTypeColor};
  }
`;

const TopicEdge = ({ id, ...props }) => {
  const [edgePath, labelX] = getSmoothStepPath({
    ...props,
    borderRadius: 12,
    centerX:
      (props.targetX + props.sourceX) / 2 + (props.data.isCommonEdge ? 100 : 0),
  });

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        style={{ strokeWidth: "1px", stroke: "#B0B0B0" }}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${
              labelX - (props.data.isCommonEdge ? 138 : 0)
            }px,${props.sourceY - 10}px)`,
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <div
            style={{
              fontSize: "10px",
              fontFamily: "Articulat CF Medium",
              color: "#ECECEC",
              lineHeight: "1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <StyledRightArrowIcon edgeTypeColor={props.data.edgeTypeColor} />
            <span style={{ color: props.data.edgeTypeColor }}>Topic</span>
          </div>
          <div
            style={{
              backgroundColor: "#0D0D0D",
              border: "1px solid #B0B0B0",
              padding: "4px 10px",
              borderRadius: "4px",
              fontSize: "10px",
              fontFamily: "Articulat CF Medium",
              color: "#ECECEC",
              lineHeight: "15.6px",
              maxWidth: "220px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {props.label}
          </div>
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default TopicEdge;
