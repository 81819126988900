import styled from "styled-components";
import Column from "../../components/common/_customColumn";
import CustomRow from "../../components/common/_customRow";
import { StyledButtonPrimary } from "../../components/common/_buttonNewOne";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useMemo } from "react";
import axiosAdapter, { getAllWorkspaces } from "../../utils";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { useState } from "react";
import WorkspaceCard from "../../components/onBoardingComponents/_defaultWorkspaceCard";
import CustomWorkspaceTextIcon from "../../assets/images/this_is_your_custom_workspace.svg";
import { env } from "../../env";
import WorkspaceBlock from "../../components/onBoardingComponents/_workspaceBlocks";
import { useCallback } from "react";
import { CreateEditWorkspaceModalContext } from "../../context/CreateEditWorkspaceModalContext";
import loadingAnimation from "../../assets/images/Loading Animation.gif";
import { ReactComponent as NoWorkspaceSVG } from "../../assets/images/no_workspace.svg";
import FlexContainer from "../../components/common/_flexContainer";
import TextSmall from "../../components/common/_text";

const PageContainer = styled.div`
  overflow-y: auto;
  padding: 1.666rem 1.666rem 0.763rem 1.666rem;
  height: calc(100vh - 3.95rem);
  background: #0d0d0d;
`;

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
  &&::-webkit-scrollbar-thumb {
    background: none;
  }
  &&::-webkit-scrollbar-thumb:hover {
    background: #aaaaaa;
  }
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  color: #f6f6f6;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 2.222rem;
  font-weight: bold;
  line-height: 2.6rem;
`;

const TextSmallStyled = styled(TextSmall)`
  width: 39.375rem;
  font-family: "Articulat CF Medium";
  font-weight: 500;
  font-size: 1.388rem;
  line-height: 1.3;
  color: #b0b0b0;
  text-align: start;
`;

const NoWorkspaceSVGStyled = styled(NoWorkspaceSVG)`
  width: 32.847rem;
  height: 29.249rem;
  margin-right: 15.277rem;
`;

const OnboardingMainPage = () => {
  const { showMessage } = useContext(SnackbarContext);
  const { userFullName, userRole } = useContext(AuthContext);
  const {
    setIsWorkspaceModalInEditMode,
    setIsCreateEditWorkspaceModalOpen,
    refetchWorkspacesMap,
  } = useContext(CreateEditWorkspaceModalContext);
  const [workspaces, setWorkspaces] = useState([]);
  const [blogPost, setBlogPost] = useState([]);
  const [workspaceLoading, setWorkspaceLoading] = useState(true);
  const [blogPostLoading, setBlogPostLoading] = useState(true);

  // Fetch Workspaces on the mount of this pages
  useMemo(async () => {
    try {
      const allWorkspaces = await getAllWorkspaces();
      setWorkspaces(allWorkspaces.data.workspaceList);
      setWorkspaceLoading(false);
    } catch (err) {
      console.log("Error: getAllWorkspaces", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Something went wrong!! Failed to fetch workspaces");
      }
      setWorkspaceLoading(false);
    }

    try {
      let fetchBlogPosts = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/getAllBlogPostList",
      );
      setBlogPost(fetchBlogPosts.data.data);
      setBlogPostLoading(false);
    } catch (err) {
      console.log("Error: getAllBlogPostList", err);
      setBlogPostLoading(false);
    }
  }, [showMessage]);

  const refetchAllWorkspaces = useCallback(async () => {
    try {
      setWorkspaceLoading(true);
      const allWorkspaces = await getAllWorkspaces();
      setWorkspaces(allWorkspaces.data.workspaceList);
      setWorkspaceLoading(false);
    } catch (err) {
      console.log("Error: getAllWorkspaces", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Something went wrong!! Failed to fetch workspaces");
      }
      setWorkspaceLoading(false);
    }
  }, [showMessage]);

  useEffect(() => {
    const currentRefetchWorkspacesMap = refetchWorkspacesMap.current;
    currentRefetchWorkspacesMap.set("OnBoardingMainPage", refetchAllWorkspaces);

    return () => {
      currentRefetchWorkspacesMap.delete("OnBoardingMainPage");
    };
  }, [refetchAllWorkspaces, refetchWorkspacesMap]);

  return (
    <PageContainer>
      <CustomRow>
        <CustomColumn
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          mb={2.222}
        >
          <Text
            style={{
              maxWidth: "calc(100% - 15rem)",
            }}
          >
            {`Welcome to Condense, ${userFullName}`}
          </Text>
          <StyledButtonPrimary
            style={{ width: "14.167rem" }}
            disabled={userRole !== "ADMIN"}
            onClick={() => {
              setIsWorkspaceModalInEditMode(false);
              setIsCreateEditWorkspaceModalOpen(true);
            }}
          >
            Add New Workspace
          </StyledButtonPrimary>
        </CustomColumn>
        <CustomColumn mb={2.222}>
          <CustomRow
            style={{
              gap: "1.112rem",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignItems: "stretch",
            }}
          >
            {workspaces.length > 1 ? (
              workspaces.map((eachWorkSpace) => (
                <CustomColumn
                  key={eachWorkSpace.id}
                  style={{ width: "calc(25% - 0.834rem)" }}
                >
                  <WorkspaceCard eachWorkSpace={eachWorkSpace} />
                </CustomColumn>
              ))
            ) : workspaces.length === 1 ? (
              <>
                <CustomColumn
                  style={{ width: "calc(25% - 0.84rem)" }}
                  key={workspaces[0].id}
                >
                  <WorkspaceCard eachWorkSpace={workspaces[0]} />
                </CustomColumn>
                <img
                  style={{
                    margin: "3.33rem 0px 0px 0.902rem",
                    height: "13rem",
                    width: "13rem",
                  }}
                  src={CustomWorkspaceTextIcon}
                  alt="CustomText"
                />
              </>
            ) : workspaceLoading ? (
              <>
                <div style={{ marginLeft: "40rem", height: "30rem" }}>
                  <img
                    src={loadingAnimation}
                    alt=""
                    style={{ height: "20rem" }}
                  />
                </div>
              </>
            ) : (
              <>
                <NoWorkspaceSVGStyled />
                <FlexContainer
                  $flexDirection="column"
                  $alignItems="flex-start"
                  $gap="1.111rem"
                  $flexWrap="nowrap"
                  style={{ alignSelf: "center" }}
                >
                  <Text>
                    {userRole === "ADMIN"
                      ? "No workspaces created"
                      : "No workspaces assigned"}
                  </Text>
                  <TextSmallStyled>
                    {userRole === "ADMIN"
                      ? "You currently have no workspaces added. Click on the button above to add a new workspace!"
                      : "You are currently not assigned to any workspace. Please contact your admin. Meanwhile, you can explore our blogs! "}
                  </TextSmallStyled>
                </FlexContainer>
              </>
            )}
          </CustomRow>
        </CustomColumn>
        <CustomColumn mb={1.667}>
          <Text>Getting Started with Condense</Text>
        </CustomColumn>
        <CustomColumn
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1.111rem",
            justifyContent: "flex-start",
            overflowX: "auto",
          }}
        >
          {blogPost.length > 0 ? (
            blogPost.map((eachBlog) => {
              return (
                <div
                  key={eachBlog.id}
                  style={{
                    flexShrink: 0,
                    width: "calc(25% - 0.84rem)",
                  }}
                >
                  <WorkspaceBlock eachBlog={eachBlog} key={eachBlog.id} />
                </div>
              );
            })
          ) : (
            <Text>
              {blogPostLoading
                ? "Loading Blog Posts..."
                : "No Blog Posts found."}
            </Text>
          )}
        </CustomColumn>
      </CustomRow>
    </PageContainer>
  );
};

export default OnboardingMainPage;
