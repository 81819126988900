import React, { useState, useEffect, useContext } from "react";
import styled, { keyframes } from "styled-components";
import Typography from "../../components/common/_typography";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Applicationcard from "./components/Applicationcard";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import { StyledButtonPrimary } from "../../components/common/_buttonNewOne";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";

const PageContainer = styled.div`
  width: 100%;
  background-color: #0d0d0d;
  overflow: "auto";
  display: flex;
  flex-direction: column;
  height: calc(100vh - 3.95rem);
`;

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const LoadingCard = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px;
  text-align: center;
  background-color: #2f2f2f;
  width: 272px;
  height: 126px;
  position: relative;
  overflow: hidden;
  background: #2f2f2f;
  background: linear-gradient(
    to right,
    #2f2f2f 0%,
    #3a3a3a 20%,
    #2f2f2f 40%,
    #2f2f2f 100%
  );
  background-size: 800px 104px;
  animation: ${shimmer} 1.2s linear infinite;
`;

const HeaderComponent = styled.div`
  padding: 1.18rem;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0d0d0d;
`;

const ContentContainer = styled.div`
  flex-direction: column;
  overflow: auto;
  flex-grow: 1;
  flex-basis: 0;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  /* padding: 16px; */
  overflow: auto;
  align-items: center;
`;

function ApplicationList({ data, loading, onDelete }) {
  const { showMessage } = useContext(SnackbarContext);

  const [applications, setApplications] = useState(data);
  const history = useHistory();

  useEffect(() => {
    setApplications(data);
  }, [data]);

  const handleDelete = async (id) => {
    try {
      const response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/deleteApplication",
        {
          applicationId: id,
        },
      );
      if (response.status === 200) {
        setApplications((prevApplications) =>
          prevApplications.filter((app) => app.id !== id),
        );
        onDelete(id);
      }
    } catch (err) {
      console.log("Error: deleteApplication", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to delete the Application");
      }
    }
  };
  const handleClick = () => {
    history.push("/createApplication");
  };

  return (
    <PageContainer>
      <HeaderComponent>
        <div>
          <Typography color="#FAFAFA" tag="p-description" text="Applications" />
        </div>
        <div>
          {/* <RoundedButton
            onClick={handleClick}
            width="200px"
            padding="8px 0px"
            text="Add New Application"
          /> */}
          <StyledButtonPrimary
            style={{
              height: "3.333rem",
              width: "14.167rem",
            }}
            onClick={handleClick}
          >
            Add New Application
          </StyledButtonPrimary>
        </div>
      </HeaderComponent>
      <ContentContainer>
        <Container>
          {loading
            ? Array.from({ length: 20 }).map((_, index) => (
                <LoadingCard key={index} />
              ))
            : applications &&
              applications?.map((application, index) => (
                <Applicationcard
                  application={application}
                  key={index}
                  onDelete={handleDelete}
                />
              ))}
        </Container>
      </ContentContainer>
    </PageContainer>
  );
}

export default ApplicationList;
