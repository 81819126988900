import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import FlexContainer from "../../../components/common/_flexContainer";
import Text from "../../../components/common/_text";
import SearchBar from "./SearchBar";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import WorkspaceNameCell from "./WorkspaceTableComponents/WorkspaceNameCell";
import WorkspaceMembersCell from "./WorkspaceTableComponents/WorkspaceMembersCell";
import WorkspaceCreatedOnCell from "./WorkspaceTableComponents/WorkspaceCreatedOnCell";
import WorkspaceDescriptionCell from "./WorkspaceTableComponents/WorkspaceDescriptionCell";
import WorkspaceEditDeleteCell from "./WorkspaceTableComponents/WorkspaceEditDeleteCell";
import { getAllWorkspaces } from "../../../utils";
import { SnackbarContext } from "../../../layouts/Context/snackBarContext";
import { AuthContext } from "../../../context/AuthContext";
import {
  Table,
  TableBody,
  TableData,
  TableRow,
  TableWrapper,
} from "../../../components/common/_table";
import WorkspaceEditMembersDialog from "./WorkspaceEditMembersDialog";
import Divider from "../../../components/common/_divider";
import { CreateEditWorkspaceModalContext } from "../../../context/CreateEditWorkspaceModalContext";
import DeleteWorkspaceConfirmationModal from "./DeleteWorkspaceConfirmationModal";

const WorkspaceDetailsSectionContents = ({
  setRefetchWorkspaces,
  refetchUsers,
}) => {
  const { showMessage } = useContext(SnackbarContext);
  const { userRole } = useContext(AuthContext);
  const {
    refetchWorkspacesMap,
    setIsCreateEditWorkspaceModalOpen,
    setIsWorkspaceModalInEditMode,
    setSelectedWorkspaceForCreateEditWorkspaceModal,
  } = useContext(CreateEditWorkspaceModalContext);

  const [workspaceData, setWorkspaceData] = useState([]);
  const [workspaceLoading, setWorkspaceLoading] = useState(true);
  const [columnFilters, setColumnFilters] = useState([]);
  const [selectedWorkspaceForEdit, setSelectedWorkspaceForEdit] =
    useState(null);
  const [
    isEditWorkspaceMembersDialogOpen,
    setIsEditWorkspaceMembersDialogOpen,
  ] = useState(false);
  const [selectedWorkspaceForDelete, setSelectedWorkspaceForDelete] =
    useState(null);
  const [isDeleteWorkspaceDialogOpen, setIsDeleteWorkspaceDialogOpen] =
    useState(false);

  const refetchWorkspaces = useCallback(async () => {
    try {
      setWorkspaceLoading(true);
      const allWorkspaces = await getAllWorkspaces();
      setWorkspaceData(allWorkspaces.data.workspaceList);
      setWorkspaceLoading(false);
    } catch (err) {
      console.log("Error: getAllWorkspaces", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Something went wrong!! Failed to fetch workspaces");
      }
      setWorkspaceLoading(false);
    }
  }, [showMessage]);

  useEffect(() => {
    const currentRefetchWorkspacesMap = refetchWorkspacesMap.current;
    currentRefetchWorkspacesMap.set(
      "WorkspaceDetailsSectionContents",
      refetchWorkspaces,
    );

    return () => {
      currentRefetchWorkspacesMap.delete("WorkspaceDetailsSectionContents");
    };
  }, [refetchWorkspaces, refetchWorkspacesMap]);

  //  TODO-NISHANT: Change all data fetches from useEffect to useMemo
  useMemo(() => {
    (async () => {
      try {
        const allWorkspaces = await getAllWorkspaces();
        setWorkspaceData(allWorkspaces.data.workspaceList);
        setWorkspaceLoading(false);
        setRefetchWorkspaces(() => refetchWorkspaces);
      } catch (err) {
        console.log("Error: getAllWorkspaces", err);
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage("Something went wrong!! Failed to fetch workspaces");
        }
        setWorkspaceLoading(false);
      }
    })();
  }, [refetchWorkspaces, setRefetchWorkspaces, showMessage]);

  const onEditButtonClick = useCallback(
    (row) => {
      setIsWorkspaceModalInEditMode(true);
      setSelectedWorkspaceForCreateEditWorkspaceModal(
        JSON.parse(JSON.stringify(row)),
      );
      setIsCreateEditWorkspaceModalOpen(true);
    },
    [
      setIsCreateEditWorkspaceModalOpen,
      setIsWorkspaceModalInEditMode,
      setSelectedWorkspaceForCreateEditWorkspaceModal,
    ],
  );

  const onEditWorkspaceMembersClick = useCallback((row) => {
    setSelectedWorkspaceForEdit(JSON.parse(JSON.stringify(row)));
    setIsEditWorkspaceMembersDialogOpen(true);
  }, []);

  const onDeleteButtonClick = useCallback((row) => {
    setSelectedWorkspaceForDelete(JSON.parse(JSON.stringify(row)));
    setIsDeleteWorkspaceDialogOpen(true);
  }, []);

  const columnHelper = useMemo(() => createColumnHelper(), []);
  const columns = useMemo(
    () => [
      columnHelper.accessor("workspaceName", {
        id: "workspaceName",
        cell: (props) => <WorkspaceNameCell row={props.row.original} />,
        filterFn: "includesString",
        meta: { width: "27%" },
      }),
      columnHelper.display({
        id: "workspaceMembers",
        cell: (props) => (
          <WorkspaceMembersCell
            row={props.row.original}
            onEditWorkspaceMembersClick={onEditWorkspaceMembersClick}
          />
        ),
        meta: { width: "19%" },
      }),
      columnHelper.display({
        id: "workspaceCreatedOn",
        cell: (props) => <WorkspaceCreatedOnCell row={props.row.original} />,
        meta: { width: "19%" },
      }),
      columnHelper.display({
        id: "workspaceDescription",
        cell: (props) => <WorkspaceDescriptionCell row={props.row.original} />,
        meta: { width: "calc(35% - 8.333rem)" },
      }),
      columnHelper.display({
        id: "workspaceEditDelete",
        cell: (props) => (
          <WorkspaceEditDeleteCell
            row={props.row.original}
            onEditButtonClick={onEditButtonClick}
            onDeleteButtonClick={onDeleteButtonClick}
            isOperationAllowed={userRole === "ADMIN"}
          />
        ),
        meta: { width: "8.333rem" },
      }),
    ],
    [
      columnHelper,
      onDeleteButtonClick,
      onEditButtonClick,
      onEditWorkspaceMembersClick,
      userRole,
    ],
  );

  const table = useReactTable({
    data: workspaceData,
    columns,
    state: {
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const workspaceSearchValue = table
    .getColumn("workspaceName")
    .getFilterValue();
  const setWorkspaceSearchValue =
    table.getColumn("workspaceName").setFilterValue;

  return (
    <>
      <FlexContainer $width="100%" $gap="1.111rem">
        <Text $fontWeight="bold" $fontSize="1.388rem">
          Workspace Details
        </Text>
        <SearchBar
          value={workspaceSearchValue}
          setValue={setWorkspaceSearchValue}
          placeholderText="Search for Workspaces"
          width="17.361rem"
        />
        {table.getRowModel().rows.length ? (
          <TableWrapper $maxHeight="24.369rem">
            <Table $width="max(64.583rem, 100%)">
              <TableBody>
                {table.getRowModel().rows.map((row) => (
                  <TableRow key={row.id} $border="0.138rem solid #2f2f2f">
                    {row.getVisibleCells().map((cell) => (
                      <TableData
                        key={cell.id}
                        $width={cell.column.columnDef.meta.width}
                        $padding="1.111rem 1.111rem 1.111rem 0"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableData>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
        ) : (
          <>
            <Divider />
            <Text $width="100%" $textAlign="center">
              {workspaceLoading
                ? "Loading Workspaces..."
                : workspaceData.length
                  ? "No workspaces found for the given search criteria"
                  : "No workspaces are created in this organisation"}
            </Text>
            <Divider />
          </>
        )}
      </FlexContainer>
      {isEditWorkspaceMembersDialogOpen && (
        <WorkspaceEditMembersDialog
          isEditWorkspaceMembersDialogOpen={isEditWorkspaceMembersDialogOpen}
          setIsEditWorkspaceMembersDialogOpen={
            setIsEditWorkspaceMembersDialogOpen
          }
          selectedWorkspaceForEdit={selectedWorkspaceForEdit}
          refetchWorkspaces={() => {
            refetchWorkspaces();
            refetchUsers();
          }}
        />
      )}
      {isDeleteWorkspaceDialogOpen && (
        <DeleteWorkspaceConfirmationModal
          isOpen={isDeleteWorkspaceDialogOpen}
          onClose={() => setIsDeleteWorkspaceDialogOpen(false)}
          refetchWorkspaces={() => {
            refetchWorkspaces();
            refetchUsers();
          }}
          dataRow={selectedWorkspaceForDelete}
        />
      )}
    </>
  );
};

export default WorkspaceDetailsSectionContents;
