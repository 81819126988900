const markSearchText = (text, escapedText) => {
  // Split the string into text and tags
  const parts = text.split(/(<[^>]*>)/);

  // Process each part
  const processed = parts.map((part) => {
    if (part.startsWith("<")) {
      // This is a tag, don't modify it
      return part;
    } else {
      // This is text, apply highlighting
      return part.replace(
        new RegExp(`(${escapedText})`, "g"),
        '<mark style="background-color: #47CCD6; padding: 0rem;">$1</mark>',
      );
    }
  });
  // Join the parts back together
  return processed.join("");
};

export default markSearchText;
