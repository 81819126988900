import styled from "styled-components";
import Column from "../../components/common/_customColumn";
import { useMemo } from "react";

const ParentContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 1.388rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const DateParentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DateContainer = styled.div`
  font-family: "Articulat CF Medium";
  padding: 0.555rem 1.111rem;
  background-color: #5e5e5e;
  color: #f6f6f6;
  border-radius: 1.388rem;
  font-size: 0.833rem;
  line-height: 1.111rem;
  font-weight: bold;
`;

const DateContainerBorder = styled.div`
  border-top: 0.0694rem solid #5e5e5e;
  flex-grow: 1;
`;

const LogCategoryParentContainer = styled(Column)`
  display: flex;
  gap: 0.555rem;
  flex-wrap: wrap;
`;

const LogCategoryTitleContainer = styled.div`
  width: 100%;
  font-family: "Articulat CF Medium";
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;

  span:nth-child(1) {
    color: #f6f6f6;
    font-size: 1.111rem;
    line-height: 1.388rem;
  }

  span:nth-child(2) {
    color: #47ccd6;
    font-size: 0.833rem;
  }
`;

const LogsContainer = styled.div`
  width: 100%;
  padding: 1.111rem;
  display: flex;
  flex-wrap: wrap;
  background-color: #2f2f2f;
  border-radius: 0.833rem;
  gap: 1.388rem;
`;

const EachLog = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  p {
    font-family: "Articulat CF Medium";
    color: #b0b0b0;
    line-height: 1.388rem;
    margin: 0;
  }

  p:nth-child(1) {
    font-size: 0.972rem;
  }

  p:nth-child(2) {
    font-size: 0.833rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 0.416rem;
  }
`;

const Dot = styled.span`
  display: inline-block;
  height: 0.277rem;
  width: 0.277rem;
  border-radius: 50%;
  background-color: #b0b0b0;
`;

const DayLogsContainer = ({ dayData }) => {
  const formattedDate = useMemo(() => {
    const date = new Date(parseInt(dayData.dateEpoch) * 1000);
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      weekday: "long",
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);

    return formattedDate;
  }, [dayData]);

  return (
    <ParentContainer>
      <DateParentContainer>
        <DateContainerBorder />
        <DateContainer>{formattedDate}</DateContainer>
        <DateContainerBorder />
      </DateParentContainer>
      {dayData.values.map((item, index) => (
        <LogCategoryParentContainer key={index} xs={9.12}>
          <LogCategoryTitleContainer>
            <span>{item.name}</span>
            <span>{`${item.logs.length} Logs`}</span>
          </LogCategoryTitleContainer>
          <LogsContainer>
            {item.logs.map((log) => (
              <EachLog key={log.id}>
                <p dangerouslySetInnerHTML={{ __html: log.activity }} />
                <p>
                  <Dot />
                  {new Intl.DateTimeFormat("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  }).format(new Date(log.createdAt * 1000))}
                </p>
              </EachLog>
            ))}
          </LogsContainer>
        </LogCategoryParentContainer>
      ))}
    </ParentContainer>
  );
};

export default DayLogsContainer;
