import styled from "styled-components";
import ReactFlow, { Background, BackgroundVariant } from "reactflow";
import GenericModal from "../common/_genericModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BackgroundImage from "../../assets/images/grid 1 (1).png";
import ConfigureCard from "../connectorsComponents/_newconfigurecard";
import PropTypes from "prop-types";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../common/_buttonNewOne";
import PublishModalCard from "../connectorsComponents/_newcardpublishmodal";

const ReactFlowContent = styled.div`
  height: 17.361rem;
  width: 100%;
  margin-top: 1.39rem;
  background-image: url(${BackgroundImage});
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ModalButtonContainer = styled.div`
  margin-top: 2.77rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.39rem;
`;

const ModalStyledButton = styled.button`
  border: 0.069rem solid #ececec;
  border-radius: 0.84rem;
  background-color: inherit;
  padding: 0.347rem 1.736rem;
  color: #ececec;
  font-family: "Articulat CF Medium";
  font-size: 1.112rem;
  font-weight: bold;
  max-width: calc(1.2 * 200px);
  min-width: calc(0.9 * 200px);
  min-height: 3.125rem;
  &:disabled {
    opacity: 0.5;
    background: #5e5e5e;
  }
  &:hover {
    background: #40909a;
  }
  &:active {
    background: #47ccd6;
  }
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
`;
const handleNoOp = () => {};
const nodeTypes = {
  custom: ConfigureCard,
};

const PublishApplicationModal = (props) => {
  const { isModelOpen, connectorName, version, description } = props;

  const history = useHistory();

  const nodes = [
    {
      id: "1",
      type: "custom",
      position: { x: 250, y: 80 },
      data: {
        title: connectorName,
        publisher: "user",
        content: description,
        version: version,
        showVectors: "true",
      },
    },
  ];

  return (
    <GenericModal show={isModelOpen} maxHeight={"80vh"} onClose={() => {}}>
      <Text
        style={{
          fontSize: "1.67rem",
          lineHeight: "2.77rem",
          color: "#F6F6F6",
          marginBottom: "1.67rem",
          fontFamily: "Articulat CF Medium",
          fontWeight: "bold",
        }}
      >
        Your new Application, “
        <span style={{ color: "#47CCD6" }}>{connectorName}</span>” is Live!
      </Text>
      <Text
        style={{
          fontSize: "1.25rem",
          lineHeight: "1.55rem",
          color: "#ECECEC",
          opacity: "0.7",
          letterSpacing: "0px",
        }}
      >
        Congratulations! Your new application is up and running! Add it to your
        pipeline and start building your solution.
      </Text>
      <ReactFlowContent>
        <PublishModalCard
          data={{
            title: connectorName,
            publisher: "user",
            content: description,
            version: version,
            showVectors: true,
          }}
          handleApplicationCardClick={handleNoOp}
        />
      </ReactFlowContent>

      <ModalButtonContainer>
        {/* <ModalStyledButton
          variant="cancel"
          onClick={() => history.push("/connectors")}
        >
          View All Connectors
        </ModalStyledButton> */}
        <StyledButtonsecondary
          variant="cancel"
          onClick={() => history.push("/connectors")}
          style={{ width: "14.167rem" }}
        >
          View All Connectors
        </StyledButtonsecondary>
        {/* <ModalStyledButton
          variant="publish"
          onClick={() => history.push("/connectors")}
          style={{ backgroundColor: "#47CCD6" }}
        >
          Done
        </ModalStyledButton> */}
        <StyledButtonPrimary
          variant="publish"
          onClick={() => history.push("/connectors")}
          style={{ width: "14.167rem" }}
        >
          Done
        </StyledButtonPrimary>
      </ModalButtonContainer>
    </GenericModal>
  );
};

PublishApplicationModal.propTypes = {
  isModelOpen: PropTypes.bool.isRequired,
  hightLightWord: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  connectorName: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  modalTitle: PropTypes.string,
  modalMessage: PropTypes.string,
  primaryButtonText: PropTypes.string,
  secondaryButtonText: PropTypes.string,
};

export default PublishApplicationModal;
