const routes = {
  CONNECTORS_PLAYGROUND: "/connectorsPlayground",
  ON_BOARDING: "/onBoarding",
  CONNECTORS: "/connectors",
  APPLICATIONS: "/applications",
  TERMINAL: "/buildLogs",
  ACTIVITY_AUDITOR: "/activityAuditor",
  // Add more routes here
};

export default routes;
