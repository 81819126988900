import React, { createContext, useMemo, useRef, useState } from "react";
import WorkspaceCreateEditModal from "../pages/WorkspaceSettings/components/WorkspaceCreateEditModal";

export const CreateEditWorkspaceModalContext = createContext({
  setIsWorkspaceModalInEditMode: () => {},
  setIsCreateEditWorkspaceModalOpen: () => {},
  setSelectedWorkspaceForCreateEditWorkspaceModal: () => {},
  refetchWorkspacesMap: {
    current: new Map(),
  },
});

const CreateEditWorkspaceModalContextProvider = ({ children }) => {
  const [isWorkspaceModalInEditMode, setIsWorkspaceModalInEditMode] =
    useState(false);
  const [isCreateEditWorkspaceModalOpen, setIsCreateEditWorkspaceModalOpen] =
    useState(false);
  const [
    selectedWorkspaceForCreateEditWorkspaceModal,
    setSelectedWorkspaceForCreateEditWorkspaceModal,
  ] = useState(null);
  const refetchWorkspacesMap = useRef(new Map());
  const createEditWorkspaceModalContextValue = useMemo(
    () => ({
      setIsWorkspaceModalInEditMode,
      setIsCreateEditWorkspaceModalOpen,
      setSelectedWorkspaceForCreateEditWorkspaceModal,
      refetchWorkspacesMap,
    }),
    [],
  );

  return (
    <CreateEditWorkspaceModalContext.Provider
      value={createEditWorkspaceModalContextValue}
    >
      {children}
      {isCreateEditWorkspaceModalOpen && (
        <WorkspaceCreateEditModal
          isEdit={isWorkspaceModalInEditMode}
          onClose={() => {
            setIsCreateEditWorkspaceModalOpen(false);
            setSelectedWorkspaceForCreateEditWorkspaceModal(null);
          }}
          isOpen={
            isWorkspaceModalInEditMode
              ? selectedWorkspaceForCreateEditWorkspaceModal &&
                isCreateEditWorkspaceModalOpen
              : isCreateEditWorkspaceModalOpen
          }
          oldWorkspaceName={
            selectedWorkspaceForCreateEditWorkspaceModal?.workspaceName
          }
          oldWorkspaceCreatedOn={
            selectedWorkspaceForCreateEditWorkspaceModal?.createdOn
          }
          oldWorkspaceDescription={
            selectedWorkspaceForCreateEditWorkspaceModal?.description
          }
          oldWorkspaceIcon={
            selectedWorkspaceForCreateEditWorkspaceModal?.iconFile
          }
          oldWorkspaceId={selectedWorkspaceForCreateEditWorkspaceModal?.id}
          refetchWorkspacesMap={refetchWorkspacesMap}
        />
      )}
    </CreateEditWorkspaceModalContext.Provider>
  );
};

export default CreateEditWorkspaceModalContextProvider;
