import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import LastUpdated from "../../../assets/images/last_updated.svg";
import moment from "moment";
import DeleteIcon from "../../../assets/images/delete.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ApplicationDeleteConfirmationDialog from "./ApplicationDeleteConfirmationDialog";
const Card = styled.div`
  box-sizing: border-box;
  border-radius: 0.6rem;
  padding: 0.8rem;
  text-align: center;
  background-color: #2f2f2f;
  width: 19.74rem;
  position: relative;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  width: 2.2rem;
  height: 2.2rem;
  background-color: #4b4b4b;
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeleteButton = styled.button`
  width: 1.4rem;
  height: 1.4rem;
  position: absolute;
  top: 0.7rem;
  right: 0.7rem;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const DonutCircle = styled.div`
  width: 1.39rem;
  height: 1.39rem;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  position: relative;

  &::before {
    content: "";
    width: 0.84rem;
    height: 0.84rem;
    background-color: #4b4b4b;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const TitleContainer = styled.div`
  margin-left: 0.6rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Title = styled.div`
  color: #ffffff;
  font-family: "Articulat CF Medium";
  font-style: normal;
  font-size: 1.112rem;
  line-height: 130%;
  font-weight: bold;
  max-width: 9.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: ${(props) =>
    props.applicationStatus === "Published" ? "normal" : "italic"};
`;

const Description = styled.div`
  color: #ffffff;
  font-family: "Articulat CF Medium";
  font-weight: 500;
  font-size: 0.7rem;
  line-height: 1.112rem;
  max-width: 11.1rem;
  letter-spacing: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: ${(props) =>
    props.applicationStatus === "Published" ? "normal" : "italic"};
`;

const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.84rem;
  color: white;
`;

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ContentTitle = styled.div`
  color: #777777;
  font-size: 0.7rem;
  line-height: 1.112rem;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0px;
`;

const ContentValue = styled.div`
  font-family: "Articulat CF Medium";
  font-weight: normal;
  font-size: 0.84rem;
  line-height: 1.112rem;
  font-weight: 500;
  color: ${(props) => props.color};
  max-width: 6.94rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Cardfooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.84rem;
  color: #ececec;
  font-size: 0.84rem;
  font-weight: 500;
  font-family: "Articulat CF Medium";
`;

function ApplicationCard({ application, onDelete }) {
  const history = useHistory();
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  return (
    <>
      <Card
        onClick={() => {
          history.push("/repoInformation", {
            isRedirected: true,
            applicationId: application.id,
          });
        }}
      >
        <DeleteButton
          onClick={(e) => {
            e.stopPropagation();
            setDeleteConfirmationOpen(true);
          }}
        >
          <img
            src={DeleteIcon}
            alt="Delete"
            style={{ height: "1.112rem", width: "1.112rem" }}
          />
        </DeleteButton>
        <CardHeader>
          <Icon>
            <DonutCircle
              color={
                application.applicationStatus === "Published"
                  ? "#259D55"
                  : "#EDB62E"
              }
            />
          </Icon>
          <TitleContainer>
            <Title applicationStatus={application.applicationStatus}>
              {application.name || "Application"}
            </Title>
            <Description applicationStatus={application.applicationStatus}>
              {application.version
                ? `version: ${application.version}`
                : "version Number Not Available"}
            </Description>
          </TitleContainer>
        </CardHeader>
        <CardContent>
          <ContentBlock>
            <ContentTitle>Repository Name</ContentTitle>
            <ContentValue
              color={
                application.applicationStatus === "Published"
                  ? "#259D55"
                  : "#EDB62E"
              }
              title={application.repositoryName}
            >
              {application.repositoryName || "--"}
            </ContentValue>
          </ContentBlock>
          <ContentBlock>
            <ContentTitle>Application Status</ContentTitle>
            <ContentValue
              color={
                application.applicationStatus === "Published"
                  ? "#259D55"
                  : "#EDB62E"
              }
            >
              {application.applicationStatus}
            </ContentValue>
          </ContentBlock>
        </CardContent>
        <Cardfooter>
          <img
            src={LastUpdated}
            alt="Last Updated"
            style={{
              marginRight: "0.6rem",
              height: "1.112rem",
              width: "1.112rem",
            }}
          />
          Last Updated {moment(application.updatedAt * 1000).fromNow()}
        </Cardfooter>
      </Card>
      <ApplicationDeleteConfirmationDialog
        dialogTitle={`Are you sure you want to delete ${
          application.name || "Unnamed Application"
        } ?`}
        onDialogClose={() => setDeleteConfirmationOpen(false)}
        showDeleteDialog={isDeleteConfirmationOpen}
        onDeleteClick={() => {
          onDelete(application.id);
          setDeleteConfirmationOpen(false);
        }}
      />
    </>
  );
}

ApplicationCard.propTypes = {
  application: PropTypes.shape({
    name: PropTypes.string,
    version: PropTypes.string,
    repositoryName: PropTypes.string,
    applicationStatus: PropTypes.string,
    updatedAt: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ApplicationCard;
