import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import DevContainerLifecycle from "../ApplicationsPage/components/DevContainerLifecycle";

const ConnectorDeploymentLifecycle = () => {
  const { showMessage } = useContext(SnackbarContext);
  const location = useLocation();
  const history = useHistory();
  const [createAppResponse, setCreateAppResponse] = useState(null);

  const deployAndSetWorkspace = useCallback(
    async (configId, newConnectorObject) => {
      try {
        let stringifiedData = JSON.stringify({
          workspaceId: parseInt(
            localStorage.getItem("selectedWorkspaceId") || 0,
          ),
          connectorId: configId,
          isDevice:
            location.state?.selectedConnectorCategory === "Telematics Device",
          newConnectorObject,
        });
        await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/setTransformDeploymentNew",
          stringifiedData,
        );
      } catch (err) {
        err.code = "DEPLOY_ERROR";
        throw err;
      }
    },
    [location.state?.selectedConnectorCategory],
  );

  const validateAndDeployConnector = useCallback(async () => {
    try {
      if (
        !location.state ||
        !location.state?.selectedConnectorCategory ||
        !location.state?.configurationId ||
        !location.state?.newConnectorObject
      ) {
        const err = new Error(
          "Required parameters missing for connector deployment",
        );
        err.code = "INPUT_ERROR";
        throw err;
      }

      await deployAndSetWorkspace(
        location.state?.configurationId,
        location.state?.newConnectorObject,
      );
      setCreateAppResponse({ code: 200 });
    } catch (err) {
      setCreateAppResponse({ code: 500 });
      if (err.code === "DEPLOY_ERROR") {
        if (err.response?.data?.remarks) showMessage(err.response.data.remarks);
        else showMessage("Oops! Your deployment has failed. Try again.");
      } else if (err.code === "INPUT_ERROR") {
        showMessage(
          "Please deploy connector by visiting the connectors page and try again",
        );
        setTimeout(() => {
          history.push("/onBoarding");
        }, 1000);
      } else {
        showMessage(
          "Failed to Deploy and Set the workspace for the selected connector",
        );
      }
      console.log("err handleNextClick 2", err);
    }
  }, [deployAndSetWorkspace, history, location.state, showMessage]);

  const handleRetry = useCallback(() => {
    setCreateAppResponse(null);
    validateAndDeployConnector();
  }, [validateAndDeployConnector]);

  useEffect(() => {
    validateAndDeployConnector();
  }, [validateAndDeployConnector]);

  return (
    <DevContainerLifecycle
      createAppResponse={createAppResponse}
      handleRetry={handleRetry}
      isDeployConnector={true}
    />
  );
};

export default ConnectorDeploymentLifecycle;
