import React, { useState } from "react";
import styled from "styled-components";
import PreviousIcon from "../../assets/images/previous.svg";
import NextIcon from "../../assets/images/next.svg";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Pages = styled.div`
  display: flex;
  align-items: center;
`;

const PageButton = styled.div`
  height: 1.67rem;
  width: 1.67rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b0b0b0;
  border-radius: 0.27rem;
  color: #4b4b4b;
  font-size: 0.97rem;
  cursor: pointer;
`;

const Text = styled.div`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  color: #cccccc;
  font-size: 1.112rem;
  letter-spacing: 0.5px;
  font-weight: bold;
`;

const JumptoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InputContainer = styled.input`
  height: 1.67rem;
  width: 3.33rem;
  border: 0.15rem solid var(--grey-2, #b0b0b0);
  border-radius: 0.29rem;
  background: none;
  color: #b0b0b0;
  text-align: center;
  outline: none;
`;
const PaginationContainer = ({
  totalCount,
  currentPage,
  onPageChange,
  pageSize,
}) => {
  const [jumpToPage, setJumpToPage] = useState("");

  const handlePageClick = (pageNumber) => {
    if (onPageChange) {
      onPageChange(pageNumber);
    }
  };

  const totalPages = Math.ceil(totalCount / pageSize);

  const handleJumpToPage = () => {
    const pageNumber = parseInt(jumpToPage, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      handlePageClick(pageNumber);
    }
    setJumpToPage("");
  };

  return (
    <Container>
      <Text>{totalCount} Entries</Text>
      {totalCount > pageSize && (
        <Pages>
          <PageButton
            disabled={currentPage === 1}
            onClick={() =>
              currentPage > 1 && handlePageClick(Math.max(1, currentPage - 1))
            }
            style={{
              marginRight: "0.5rem",
              opacity: currentPage === 1 ? 0.4 : 1,
              pointerEvents: currentPage === 1 ? "none" : "auto",
            }}
          >
            <img
              src={PreviousIcon}
              alt=""
              style={{ height: "1.12rem", width: "1.12rem" }}
            />
          </PageButton>
          {Array.from({ length: totalPages }, (_, index) => {
            const pageNumber = index + 1;
            return (
              <PageButton
                key={index}
                style={{
                  backgroundColor:
                    currentPage === pageNumber ? "#b0b0b0" : "#2f2f2f",
                  color: currentPage === pageNumber ? "#4b4b4b" : "#b0b0b0",
                }}
                onClick={() => handlePageClick(pageNumber)}
              >
                {pageNumber}
              </PageButton>
            );
          })}

          <PageButton
            style={{
              marginLeft: "0.5rem",
              opacity: currentPage === totalPages ? 0.4 : 1,
              pointerEvents: currentPage === totalPages ? "none" : "auto",
            }}
            onClick={() =>
              currentPage < totalPages &&
              handlePageClick(Math.min(totalPages, currentPage + 1))
            }
          >
            <img
              src={NextIcon}
              alt=""
              style={{ height: "1.12rem", width: "1.12rem" }}
            />
          </PageButton>
          <JumptoContainer>
            <Text style={{ marginLeft: "2.5rem", color: "#b0b0b0" }}>
              Jump to
            </Text>
            <InputContainer
              style={{ marginLeft: "0.5rem" }}
              value={jumpToPage}
              onChange={(e) => setJumpToPage(e.target.value)}
            />
            <PageButton
              style={{ marginLeft: "0.5rem" }}
              onClick={handleJumpToPage}
            >
              <img
                src={NextIcon}
                alt=""
                style={{ height: "1.12rem", width: "1.12rem" }}
              />
            </PageButton>
          </JumptoContainer>
        </Pages>
      )}
    </Container>
  );
};

export default PaginationContainer;
