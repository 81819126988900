import React from "react";
import Text from "../../../components/common/_text";
import styled from "styled-components";
import { StyledButtonPrimary } from "../../../components/common/_buttonNewOne";
import PipelineIcon from "../../../assets/images/pipeline_bg.svg";
import { useHistory } from "react-router-dom";

const FlexItem = styled.div`
  flex: 0 0 100%;
  margin: ${({ $margin }) => $margin || ""};
`;

const PrimaryButtonStyled = styled(StyledButtonPrimary)`
  width: 19.166rem;
  height: 3.055rem;
`;

const PipelineIconStyled = styled.img`
  height: calc(100vh - 27.85rem);
  width: 100%;
`;

const NoNodes = () => {
  const history = useHistory();
  return (
    <>
      <FlexItem $margin="0 0 2.222rem 0">
        <Text $fontSize="3.333rem" $fontWeight="bold">
          Ready to create your pipeline?
        </Text>
      </FlexItem>
      <FlexItem $margin="0 0 1.875rem 0">
        <Text
          $fontSize="1.666rem"
          $color="#CCCCCC"
          $width="38.5rem"
          $letterSpacing="0px"
          $fontWeight=""
        >
          You currently have no connectors added. Click on the button below to
          get started!
        </Text>
      </FlexItem>
      <FlexItem $margin="0 0 2.569rem 0">
        <PrimaryButtonStyled onClick={() => history.push("/connectors")}>
          Add Connector
        </PrimaryButtonStyled>
      </FlexItem>
      <PipelineIconStyled
        style={{ fontWeight: "" }}
        src={PipelineIcon}
        alt="PipelineIcon"
      />
    </>
  );
};

export default NoNodes;
