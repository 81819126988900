import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import EmptyState from "../../assets/images/C2D Empty State.svg";
import { StyledButtonPrimary } from "../common/_buttonNewOne";
import Search from "../../assets/images/searchIcon.svg";
import Column from "../common/_customColumn";
import CampaignModal from "./CampaignModal";
// import { env } from "../../env";
import axiosAdapter from "../../utils";
import DeleteIcon from "../../assets/images/delete.svg";
import PaginationContainer from "../common/_paginationContainer";
import GenericDeleteModal from "../common/_genericdeleteModal";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import useDebouncedCallback from "../../hooks/useDebouncedCallback";
import CommandPage from "./CommandPage";
import Emptystate from "../../assets/images/searchemptystate.svg";
import { env } from "../../env";
import GenericProgressBar from "../common/_genericProgressBar";

const CustomRow = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #4b4b4b;
  padding: 1.112rem 1.25rem;
  justify-content: space-between;

  & > div {
    align-content: center;
  }
`;

const Content = styled.div`
  font-family: Articulat CF Medium;
  font-size: 1.67rem;
  line-height: 2.167rem;
  letter-spacing: -0.02em;
  color: #cccccc;
  width: 30.34rem;
`;

const Title = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 1.67rem;
  font-weight: bold;
  line-height: 2.88rem;
  color: #f6f6f6;
`;

export const StyledButtonsecondary = styled.button`
  all: unset;
  width: 13.6rem;
  color: #f6f6f6;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-family: "Articulat CF Medium";
  letter-spacing: 0.035rem;
  font-weight: bold;
  font-size: 0.972rem;
  border-radius: 0.55rem;
  height: 2.77rem;
  text-align: center;
  border: 0.1rem solid ${(props) => (props.disabled ? "#797979" : "#FAFAFA")};
  &:hover {
    background: transparent;
    border: 0.1rem solid #47ccd6;
    color: #47ccd6;
  }
  &:active {
    background: #1e565a;
    box-shadow: 0px 0.55rem 1.112rem 0px rgba(0, 0, 0, 0.3) inset;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Container = styled.div`
  background-color: #2f2f2f;
  width: 100%;
  margin: 1.112rem 1.112rem 1.38rem 1.112rem;
  padding: 1.112rem;
  padding-top: 1.38rem;
  border-radius: 0.84rem;
`;

const SearchBox = styled.div`
  border: 2px solid #b0b0b0;
  width: 17.222rem;
  height: 2.77rem;
  margin-left: 0.277rem;
  border-radius: 0.55rem;
  padding: 0.48rem;
  display: flex;
  position: relative;
`;

const Text = styled.div`
  font-family: "Articulat CF Medium";
  color: #b0b0b0;
`;

const SearchInput = styled.input`
  color: #cccccc;
  border: none;
  background: transparent;
  margin-left: 0.5rem;
  outline: none;
  width: 100%;
  font-family: "Articulat CF Medium";

  &::placeholder {
    color: #cccccc;
  }
`;

const CellHeader = styled.div`
  width: 100%;
  height: 3.89rem;
  background-color: #4b4b4b;
  border-radius: 0.84rem;
  margin-top: 2.084rem;
  display: flex;
  padding: 1.25rem;
  justify-content: space-between;
`;

const DataContainer = styled.div`
  overflow-y: scroll;
  height: 23.39rem;
`;

const formatDate = (epoch) => {
  const date = new Date(epoch * 1000);
  const day = date.getDate().toString().padStart(2, "0");
  const month = date.toLocaleString("en-GB", { month: "short" });
  const year = date.getFullYear();
  return `${day} ${month}, ${year}`;
};

function CampaignPageContainer() {
  const [campaigns, setCampaigns] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [selectedCommand, setSelectedCommand] = useState(null);
  const { showMessage } = useContext(SnackbarContext);
  const [isEmptyState, setEmptyState] = useState(false);
  const pageSize = 6;

  const fetchCampaigns = async (page, pageSize = 6, searchKeyword = "") => {
    try {
      const response = await axiosAdapter(
        "GET",
        env.REACT_APP_URL +
          `command/getAllCampaign?page=${page}&pageSize=${pageSize}&searchKeyword=${searchKeyword}`,
        {},
      );
      const fetchedCampaigns = response?.data?.data;
      const count = response.data.totalCount;
      setTotalCount(count);
      setCampaigns(fetchedCampaigns ? fetchedCampaigns : []);
      setEmptyState(false);
    } catch (error) {
      console.log("Error fetching campaigns:", error);
      if (error.response?.data?.status === 404) {
        setEmptyState(true);
      }
    }
  };
  const resetpage = () => {
    setCurrentPage(1);
  };
  useEffect(() => {
    fetchCampaigns(currentPage, pageSize, searchTerm);
  }, [currentPage, searchTerm]);

  const debouncedFetchCampaigns = useDebouncedCallback((keyword) => {
    setCurrentPage(1);
    fetchCampaigns(1, pageSize, keyword);
  }, 300);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchCampaigns(pageNumber, pageSize, searchTerm);
  };

  const handleSearch = (event) => {
    const value = event.target.value || "";
    setSearchTerm(value);
    setCurrentPage(1);
    debouncedFetchCampaigns(value);
  };

  const handleCreateCampaign = () => {
    setIsModalOpen(true);
  };

  // const handleSelectCampaign = (campaign) => {
  //   setSearchTerm(campaign.name);
  //   setSearchResults([]);
  // };

  const handleCampaignClick = (campaign) => {
    setSelectedCommand(campaign);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    // setIsDeleteModalOpen(false);
  };

  const handleCommandPageClose = () => {
    setSelectedCommand(null);
  };

  const handleAddCampaign = (newCampaign) => {
    setCampaigns((prevCampaigns) => [...prevCampaigns, newCampaign]);
    setIsModalOpen(false);
  };

  const handleDeleteModal = (campaign) => {
    setSelectedCampaign(campaign);
    setIsDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    if (selectedCampaign) {
      try {
        const result = await axiosAdapter(
          "POST",
          "https://ncvocj.condense.zeliot.in/command/deleteCampaign",
          { campaignId: selectedCampaign.id },
        );
        showMessage(result.data.message, "success");
        setIsDeleteModalOpen(false);
        setSelectedCampaign(null);
        fetchCampaigns(currentPage, pageSize);
      } catch (error) {
        showMessage(
          error.message || "An error occurred while deleting the campaign",
          "error",
        );
      }
    }
  };
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    // setSelectedCampaign(null);
  };

  return (
    <Container>
      {campaigns && campaigns.length === 0 && searchTerm === "" ? (
        <div style={{ display: "flex" }}>
          <img src={EmptyState} alt="Empty State" />
          <div style={{ paddingTop: "17rem", paddingLeft: "11.527rem" }}>
            <Title>Get Started...</Title>
            <Content style={{ paddingTop: "1.112rem" }}>
              No campaigns added yet. Click the button below to get started!
            </Content>
            <div style={{ paddingTop: "3.194rem" }}>
              <StyledButtonPrimary
                width="18.75rem"
                onClick={handleCreateCampaign}
              >
                Create New Campaign
              </StyledButtonPrimary>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <SearchBox>
              <img
                style={{ height: "1.5rem", width: "1.5rem" }}
                src={Search}
                alt=""
              />
              <SearchInput
                type="text"
                placeholder="Search Campaign"
                value={searchTerm}
                onChange={handleSearch}
              />
            </SearchBox>
            <StyledButtonsecondary
              width="13.6rem"
              onClick={handleCreateCampaign}
            >
              Create New Campaign
            </StyledButtonsecondary>
          </div>
          <CellHeader>
            <Column xs={1.9}>
              <Text>Campaign Name</Text>
            </Column>
            <Column xs={1.9}>
              <Text>Created on</Text>
            </Column>
            <Column xs={1.9}>
              <Text>Created By</Text>
            </Column>
            <Column xs={1.9}>
              <Text>Expiry Date</Text>
            </Column>
            <Column xs={2.4}>
              <Text>Campaign Progress</Text>
            </Column>
            <Column xs={1}>
              <Text>Actions</Text>
            </Column>
          </CellHeader>
          {isEmptyState ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "cente",
              }}
            >
              <img
                src={Emptystate}
                alt="Empty State"
                style={{ height: "25rem" }}
              />
            </div>
          ) : (
            <>
              <DataContainer>
                {campaigns.map((campaign, index) => (
                  <CustomRow
                    key={index}
                    style={{ height: "3.88rem" }}
                    onClick={() => handleCampaignClick(campaign)}
                  >
                    <Column xs={1.9}>
                      <Text>{campaign.campaignName}</Text>
                    </Column>
                    <Column xs={1.9}>
                      <Text>{formatDate(campaign.createdOn)}</Text>
                    </Column>
                    <Column xs={1.9}>
                      <Text>{campaign.createdBy}</Text>
                    </Column>
                    <Column xs={1.9}>
                      <Text>{formatDate(campaign.expiryDate)}</Text>
                    </Column>
                    <Column xs={2.4}>
                      <GenericProgressBar
                        progressText={"Complete"}
                        progressTotal={parseInt(campaign.totalCount)}
                        progressCount={parseInt(campaign.executedCount)}
                        showProgressCount
                      />
                    </Column>
                    <Column xs={1}>
                      <img
                        src={DeleteIcon}
                        alt=""
                        style={{
                          height: "1.67rem",
                          width: "1.67rem",
                          cursor: "pointer",
                          filter:
                            hoveredIndex === index
                              ? "brightness(0) saturate(100%) invert(31%) sepia(89%) saturate(5088%) hue-rotate(335deg) brightness(92%) contrast(97%)"
                              : "none",
                        }}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteModal(campaign);
                        }}
                      />
                    </Column>
                  </CustomRow>
                ))}
              </DataContainer>
              <div style={{ paddingTop: "0.64rem", paddingBottom: "0.64rem" }}>
                <PaginationContainer
                  totalCount={totalCount}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                  pageSize={pageSize}
                />
              </div>
            </>
          )}
        </>
      )}
      {isModalOpen && (
        <CampaignModal
          isModalOpen={isModalOpen}
          onClose={handleCloseModal}
          onAddCampaign={handleAddCampaign}
          fetchCampaigns={fetchCampaigns}
          resetpage={resetpage}
        />
      )}
      {isDeleteModalOpen && (
        <GenericDeleteModal
          title={`Are you sure you want to delete the  Campaign Name"?`}
          message={
            "If you delete this campaign, you will have to recreate it to run it again. Please be sure before you proceed."
          }
          deleteButtonText={"Delete Campaign"}
          onDelete={handleDelete}
          isModalOpen={isDeleteModalOpen}
          onClose={handleCloseDeleteModal}
        />
      )}
      {selectedCommand && (
        <CommandPage
          command={selectedCommand}
          onClose={handleCommandPageClose}
        />
      )}
    </Container>
  );
}

export default CampaignPageContainer;
