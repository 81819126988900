import React, { useState } from "react";
import FlexContainer from "../../../components/common/_flexContainer";
import Text from "../../../components/common/_text";
import styled from "styled-components";
import {
  StyledButtonsecondary,
  StyledPrimaryButton2,
} from "../../../components/common/_buttonNewOne";
import Divider from "../../../components/common/_divider";
import { SignOutConfirmationModal } from "./SignOutConfirmationModal";
import DeleteAccountConfirmationModal from "./DeleteAccountConfirmationModal";

const PrimaryButton2Styled = styled(StyledPrimaryButton2)`
  height: 2.777rem;
  border-radius: 0.555rem;
`;
const SecondaryButtonStyled = styled(StyledButtonsecondary)`
  height: 2.777rem;
  border-radius: 0.555rem;
`;

const AccountActionsSectionContents = () => {
  const [isSignOutModalOpen, setSignOutModalOpen] = useState(false);
  const [isDeleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);

  return (
    <>
      <FlexContainer $width="100%" $justifyContent="flex-start" $gap="1.666rem">
        <Text $width="100%" $fontWeight="bold" $fontSize="1.388rem">
          Account Actions
        </Text>
        <Divider />
        <FlexContainer $width="100%" $gap="1.666rem">
          <FlexContainer
            $width="calc(100% - 12.222rem - 1.666rem)"
            $flexDirection="column"
            $justifyContent="flex-start"
            $alignItems="flex-start"
            $gap="0.277rem"
          >
            <Text $fontWeight="bold">Sign Out</Text>
            <Text $fontSize="0.833rem" $color="#777777">
              You can safely sign out from the current session and sign back in
              later.
            </Text>
          </FlexContainer>
          <SecondaryButtonStyled
            width="12.222rem"
            onClick={() => setSignOutModalOpen(true)}
          >
            Sign Out
          </SecondaryButtonStyled>
          <FlexContainer
            $width="calc(100% - 12.222rem - 1.666rem)"
            $flexDirection="column"
            $justifyContent="flex-start"
            $alignItems="flex-start"
            $gap="0.277rem"
          >
            <Text $fontWeight="bold">Account Deletion</Text>
            <Text $fontSize="0.833rem" $color="#777777">
              By deleting your account, you delete all associated data.
            </Text>
          </FlexContainer>
          <PrimaryButton2Styled
            width="12.222rem"
            onClick={() => setDeleteAccountModalOpen(true)}
          >
            Delete Account
          </PrimaryButton2Styled>
        </FlexContainer>
      </FlexContainer>
      {isSignOutModalOpen && (
        <SignOutConfirmationModal
          isOpen={isSignOutModalOpen}
          onClose={() => setSignOutModalOpen(false)}
        />
      )}
      {isDeleteAccountModalOpen && (
        <DeleteAccountConfirmationModal
          isOpen={isDeleteAccountModalOpen}
          onClose={() => setDeleteAccountModalOpen(false)}
        />
      )}
    </>
  );
};

export default AccountActionsSectionContents;
