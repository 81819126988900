import styled from "styled-components";
import CustomRow from "../../components/common/_customRow";
import Column from "../../components/common/_customColumn";
import { ReactComponent as BackIcon } from "../../assets/images/left_arrow_icon.svg";
import GenericSearchBar from "../../components/common/_genericSearchBar";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import GenericSelectionWithLabel from "../../components/common/_genericSelectionWithLabel";
import ToggleButton from "../../components/common/_toggleButtonNew";
import { ReactComponent as CheckedIcon } from "../../assets/images/checkbox_checked.svg";
import { ReactComponent as UnCheckedIcon } from "../../assets/images/checkbox_unchecked.svg";
import { ReactComponent as CancelIcon } from "../../assets/images/Close.svg";
import { AuthContext } from "../../context/AuthContext";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import useDebounce from "./debouceCustomHook";
import DayLogsContainer from "./DayLogsContainer";
import markSearchText from "./utils/markSearchText";
import getStartAndEndTime from "./utils/getStartAndEndTime";
import NoLogsImage from "../../assets/images/no_logs_preview.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

const AuditorLogTimeRangeOptions = [
  {
    value: 0,
    name: "For Today",
  },
  {
    value: 2,
    name: "For last 2 Days",
  },
  {
    value: 7,
    name: "For last 7 Days",
  },
  {
    value: 15,
    name: "For last 15 Days",
  },
  {
    value: 30,
    name: "For last 30 Days",
  },
];

const AuditorLogOrderOptions = [
  {
    value: true,
    name: "Newest to Oldest",
  },
  {
    value: false,
    name: "Oldest to Newest",
  },
];

const PageContainer = styled.div`
  width: 100%;
  background-color: #0d0d0d;
  overflow: "auto";
  display: flex;
  flex-direction: column;
  height: calc(100vh - 3.95rem);
`;

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const StyledBackIcon = styled(BackIcon)`
  cursor: pointer;
  height: 2.222rem;
  width: 2.222rem;
`;

const StyledText = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
  color: ${({ $color }) => ($color ? `${$color}` : "#f6f6f6")};
  font-size: ${({ $fontSize }) => ($fontSize ? `${$fontSize}rem` : "1.666rem")};
  font-weight: ${({ $fontWeight }) => ($fontWeight ? `${$fontWeight}` : "500")};
  line-height: ${({ $lineHeight }) =>
    $lineHeight ? `${$lineHeight}rem` : "1"};

  .addEllipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const TopBarSectionContainer = styled.div`
  display: flex;
  gap: 1.111rem;
  align-items: center;
`;

const CheckBoxSelectionContainer = styled.div`
  padding: 0.694rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.694rem;
  color: #f6f6f6;
  font-size: 0.972rem;
  font-weight: bold;
  font-family: "Articulat CF Medium";
  line-height: 1.111rem;
  cursor: pointer;
  border-radius: 0.555rem;
  &:hover {
    background-color: #4b4b4b;
  }
  svg {
    flex-shrink: 0;
  }
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const StyledCheckedIcon = styled(CheckedIcon)`
  height: 1.388rem;
  width: 1.388rem;
`;

const StyledUnCheckedIcon = styled(UnCheckedIcon)`
  height: 1.388rem;
  width: 1.388rem;
`;

const ActionChip = styled.div`
  display: flex;
  align-items: center;
  gap: 1.111rem;
  justify-content: flex-start;
  padding: 0.555rem 0.833rem;
  font-family: "Articulat CF Medium";
  font-size: 0.972rem;
  line-height: 1.111rem;
  border-radius: 1.388rem;
  cursor: pointer;
  color: ${({ $isSelected }) => ($isSelected ? `#47CCD6` : "#f6f6f6")};
  border: 0.069rem solid
    ${({ $isSelected }) => ($isSelected ? "#47CCD6" : "#b0b0b0")};
  background-color: ${({ $isSelected }) =>
    $isSelected ? "#47CCD629" : "inherit"};
`;

const StyledCancelIcon = styled(CancelIcon)`
  height: 0.833rem;
  width: 0.833rem;
  cursor: pointer;
`;

const getAllWorkspacesQuickly = async () => {
  const response = await axiosAdapter(
    "GET",
    env.REACT_APP_URL + "user-management/getAllWorkspacesQuickly",
  );
  return response.data;
};

const getAllOrgMembersQuickly = async () => {
  const response = await axiosAdapter(
    "GET",
    env.REACT_APP_URL + "user-management/getOrganizationMembersQuickly",
  );
  return response.data;
};

const getAllActivityLogs = async (payload) => {
  const response = await axiosAdapter(
    "POST",
    env.REACT_APP_URL + "activity/getActivityLogs",
    payload,
  );
  return response.data;
};

const getActionsMapFromLocalstorage = () => {
  let actionsMap = localStorage.getItem("auditorLogsActions");
  if (!actionsMap) {
    return new Map([
      ["Account Actions", true],
      ["Pipelines", true],
      ["Workspaces", true],
      ["Connectors", true],
      ["Applications", true],
      ["Users & Roles", true],
    ]);
  }
  try {
    const checkMap = {
      "Account Actions": true,
      Pipelines: true,
      Workspaces: true,
      Connectors: true,
      Applications: true,
      "Users & Roles": true,
    };
    actionsMap = JSON.parse(actionsMap);
    if (!Array.isArray(actionsMap)) {
      throw new Error("Localstorage value not an array");
    }
    if (actionsMap.length !== 6) {
      throw new Error("Localstorage value length not equals to 6");
    }
    for (let i = 0; i < actionsMap.length; i++) {
      if (!Array.isArray(actionsMap[i])) {
        throw new Error("Localstorage value not an array of array");
      }
      if (actionsMap[i].length !== 2) {
        throw new Error("Localstorage value not an array of array of length 2");
      }
      if (!checkMap[actionsMap[i][0]]) {
        throw new Error(
          `Localstorage value for ${actionsMap[i][0]} is not a valid string`,
        );
      }
      if (typeof actionsMap[i][1] !== "boolean") {
        throw new Error(
          `Localstorage value for ${actionsMap[i][0]} is not a boolean`,
        );
      }
    }
    return new Map(actionsMap);
  } catch (err) {
    console.log("Error: auditorLogsActions localstorage format", err);
    return new Map([
      ["Account Actions", true],
      ["Pipelines", true],
      ["Workspaces", true],
      ["Connectors", true],
      ["Applications", true],
      ["Users & Roles", true],
    ]);
  }
};

const getSelectedTimeRange = () => {
  let timeRange = localStorage.getItem("auditLogsTimeRange");
  if (!timeRange) {
    return AuditorLogTimeRangeOptions[0];
  }
  try {
    timeRange = JSON.parse(timeRange);
    if (typeof timeRange !== "object") {
      throw new Error("Localstorage value not an object");
    }
    if (Object.keys(timeRange).length !== 2) {
      throw new Error("Localstorage value not an object of 2 keys");
    }
    if (
      typeof timeRange.name !== "string" ||
      typeof timeRange.value !== "number"
    ) {
      throw new Error(
        "Localstorage value not an object of 2 keys with expected values",
      );
    }
    const timeRangeOptionIndex = AuditorLogTimeRangeOptions.findIndex(
      (option) => option.name === timeRange.name,
    );
    if (timeRangeOptionIndex === -1) {
      throw new Error(
        "Localstorage value does not match with the standard options",
      );
    }
    return AuditorLogTimeRangeOptions[timeRangeOptionIndex];
  } catch (err) {
    console.log("Error: auditorLogsTimeRange localstorage format", err);
    return AuditorLogTimeRangeOptions[0];
  }
};

const getSelectedLogOrder = () => {
  let logOrder = localStorage.getItem("auditLogsOrder");
  if (!logOrder) {
    return AuditorLogOrderOptions[0];
  }
  try {
    logOrder = JSON.parse(logOrder);
    if (typeof logOrder !== "object") {
      throw new Error("Localstorage value not an object");
    }
    if (Object.keys(logOrder).length !== 2) {
      throw new Error("Localstorage value not an object of 2 keys");
    }
    if (
      typeof logOrder.name !== "string" ||
      typeof logOrder.value !== "boolean"
    ) {
      throw new Error(
        "Localstorage value not an object of 2 keys with expected values",
      );
    }
    const logOrderOptionIndex = AuditorLogOrderOptions.findIndex(
      (option) => option.name === logOrder.name,
    );
    if (logOrderOptionIndex === -1) {
      throw new Error(
        "Localstorage value does not match with the standard options",
      );
    }
    return AuditorLogOrderOptions[logOrderOptionIndex];
  } catch (err) {
    console.log("Error: auditorLogsOrder localstorage format", err);
    return AuditorLogOrderOptions[0];
  }
};

const ActivityAuditor = () => {
  // Context Values
  const history = useHistory();
  const { userRole } = useContext(AuthContext);
  const { showMessage } = useContext(SnackbarContext);

  // State values
  const [searchText, setSearchText] = useState("");
  const [selectedTimeRange, setSelectedTimeRange] =
    useState(getSelectedTimeRange);
  const [selectedLogOrder, setSelectedLogOrder] = useState(getSelectedLogOrder);
  const [isWorkspaceToggleSelected, setWorkspaceToggleSelected] =
    useState(true);
  const [isSelectAllChecked, setSelectAllChecked] = useState(false);
  const [isWorkspaceOrMemberListLoading, setWorkspaceOrMemberListLoading] =
    useState(true);
  const [workspaceOrMemberListError, setWorkspaceOrMemberListError] =
    useState("");
  const [workspaceList, setWorkspaceList] = useState([]);
  const [selectedWorkspaceMap, setSelectedWorkspaceMap] = useState(new Map());
  const [membersList, setMembersList] = useState([]);
  const [selectedMembersMap, setSelectedMembersMap] = useState(new Map());
  const [actionsMap, setActionsMap] = useState(getActionsMapFromLocalstorage);
  const [isAuditorLogLoading, setAuditorLogLoading] = useState(true);
  const [auditorLogError, setAuditorLogError] = useState("");
  const [auditorLogs, setAuditorLogs] = useState([]);

  // Functions
  const onSearchTextChange = useCallback((text) => {
    setSearchText(text);
  }, []);

  const debouncedSearchText = useDebounce({ value: searchText, delay: 250 });

  const handleSelectAllClick = useCallback(
    (isWorkspaceToggleSelected) => {
      setSelectAllChecked((prevSelectAll) => {
        if (prevSelectAll) {
          if (isWorkspaceToggleSelected) {
            setSelectedWorkspaceMap(
              new Map(workspaceList.map((workspace) => [workspace.id, false])),
            );
          } else {
            setSelectedMembersMap(
              new Map(membersList.map((member) => [member.id, true])),
            );
          }
          return false;
        } else {
          if (isWorkspaceToggleSelected) {
            setSelectedWorkspaceMap(
              new Map(workspaceList.map((workspace) => [workspace.id, true])),
            );
          } else {
            setSelectedMembersMap(
              new Map(membersList.map((member) => [member.id, true])),
            );
          }
          return true;
        }
      });
    },
    [membersList, workspaceList],
  );

  // Effects
  useEffect(() => {
    if (userRole === "ADMIN" || userRole === "USER") {
      (async () => {
        try {
          // Paralallely fetching the workspaces and members list
          const fetchResponse = await Promise.allSettled([
            getAllWorkspacesQuickly(),
            ...(userRole === "ADMIN" ? [getAllOrgMembersQuickly()] : []),
          ]);
          if (fetchResponse[0].status === "fulfilled") {
            setWorkspaceList(fetchResponse[0].value.data);
            setSelectedWorkspaceMap(
              new Map(
                fetchResponse[0].value.data.map((workspace) => [
                  workspace.id,
                  true,
                ]),
              ),
            );
            setSelectAllChecked(true);
          } else {
            throw fetchResponse[0].reason;
          }

          if (userRole === "ADMIN") {
            if (fetchResponse[1].status === "fulfilled") {
              setMembersList(fetchResponse[1].value.data);
              setSelectedMembersMap(
                new Map(
                  fetchResponse[1].value.data.map((member) => [
                    member.id,
                    true,
                  ]),
                ),
              );
            } else {
              throw fetchResponse[1].reason;
            }
          }
          setWorkspaceOrMemberListLoading(false);
        } catch (err) {
          console.log("Error:", err);
          if (err.response?.data?.remarks) {
            showMessage(err.response.data.remarks);
            setAuditorLogError(err.response.data.remarks);
            setWorkspaceOrMemberListError(err.response.data.remarks);
          } else {
            showMessage("Unable to fetch the workspaces and members list");
            setAuditorLogError(
              "Unable to fetch the workspaces and members list",
            );
            setWorkspaceOrMemberListError(
              "Unable to fetch the workspaces and members list",
            );
          }
          setWorkspaceOrMemberListLoading(false);
          setAuditorLogLoading(false);
          return;
        }
      })();
    }
  }, [showMessage, userRole]);

  useEffect(() => {
    if (isWorkspaceOrMemberListLoading) return;
    if (workspaceOrMemberListError) return;

    setAuditorLogLoading(true);
    const { startTime, endTime } = getStartAndEndTime(selectedTimeRange.value);
    const payload = {
      timezone: TIME_ZONE,
      order: selectedLogOrder.value,
      startTime,
      endTime,
      action: [...actionsMap.entries()]
        .filter(([_, val]) => val)
        .map(([key]) => key),
      ...(isWorkspaceToggleSelected
        ? {
            workspaceIds: [...selectedWorkspaceMap.entries()]
              .filter(([, val]) => val)
              .map(([key]) => key),
          }
        : {
            userIds: [...selectedMembersMap.entries()]
              .filter(([, val]) => val)
              .map(([key]) => key),
          }),
    };
    (async () => {
      try {
        const response = await getAllActivityLogs(payload);
        setAuditorLogs(response.data);
        setAuditorLogLoading(false);
      } catch (err) {
        console.log("Error: getActivityLogs", err);
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
          setAuditorLogError(err.response.data.remarks);
        } else {
          showMessage("Unable to fetch the activity logs");
          setAuditorLogError("Unable to fetch the activity logs");
        }
        setAuditorLogLoading(false);
      }
    })();
  }, [
    isWorkspaceOrMemberListLoading,
    showMessage,
    workspaceOrMemberListError,
    actionsMap,
    selectedWorkspaceMap,
    selectedMembersMap,
    isWorkspaceToggleSelected,
    selectedLogOrder,
    selectedTimeRange,
  ]);

  // Memoized values
  const isAllActionsSelected = useMemo(() => {
    return [...actionsMap.values()].every((val) => val);
  }, [actionsMap]);

  const filteredAuditorLogs = useMemo(() => {
    const auditorLogsCopy = JSON.parse(JSON.stringify(auditorLogs));

    if (!debouncedSearchText) return auditorLogsCopy;

    // Escape special characters for the search text
    const escapedText = debouncedSearchText.replace(
      /[-/\\^$*+?.()|[\]{}]/g,
      "\\$&",
    );

    for (let dayObj of auditorLogsCopy) {
      for (let logCategory of dayObj.values) {
        for (let log of logCategory.logs) {
          log.activity = markSearchText(log.activity, escapedText);
        }
      }
    }

    return auditorLogsCopy;
  }, [debouncedSearchText, auditorLogs]);

  return (
    <PageContainer>
      <CustomRow>
        <CustomColumn
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1.18rem",
            borderBottom: "0.0694rem solid #4B4B4B",
          }}
        >
          <TopBarSectionContainer>
            <StyledBackIcon onClick={() => history.goBack()} />
            <StyledText $fontWeight="bold">Activity Auditor</StyledText>
          </TopBarSectionContainer>
          <TopBarSectionContainer>
            <GenericSearchBar
              placeholderText={"Search"}
              onSearchTextChange={onSearchTextChange}
              searchText={searchText}
            />
            <GenericSelectionWithLabel
              label={"Showing Logs for"}
              selectedOption={selectedTimeRange}
              selectionNameFunction={(option) => option.name}
              selectionOptions={AuditorLogTimeRangeOptions}
              onSelectionChange={(option) => {
                setSelectedTimeRange(option);
                localStorage.setItem(
                  "auditLogsTimeRange",
                  JSON.stringify(option),
                );
              }}
              dropdownWidth={"11.45rem"}
            />
            <GenericSelectionWithLabel
              selectedOption={selectedLogOrder}
              selectionNameFunction={(option) => option.name}
              selectionOptions={AuditorLogOrderOptions}
              onSelectionChange={(option) => {
                setSelectedLogOrder(option);
                localStorage.setItem("auditLogsOrder", JSON.stringify(option));
              }}
            />
          </TopBarSectionContainer>
        </CustomColumn>
        <CustomColumn
          xs={2}
          style={{
            height: "calc(100vh - 3.95rem - 5.22rem)",
            overflow: "auto",
            backgroundColor: "#2F2F2F",
            borderRight: "0.0694rem solid #4B4B4B",
          }}
        >
          {isWorkspaceOrMemberListLoading ? (
            <StyledText>Loading...</StyledText>
          ) : workspaceOrMemberListError ? (
            <StyledText>{workspaceOrMemberListError}</StyledText>
          ) : (
            <>
              <CustomRow
                style={{
                  padding: "1.111rem 1.111rem 0.555rem 1.111rem",
                  borderBottom: "0.0694rem solid #4B4B4B",
                  gap: "0.555rem",
                }}
              >
                <CustomColumn>
                  <ToggleButton
                    height="2.5rem"
                    toggleValue={isWorkspaceToggleSelected}
                    toggleFunction={(value) => {
                      setWorkspaceToggleSelected(value);
                      setSelectAllChecked(true);
                      if (value) {
                        setSelectedWorkspaceMap(
                          new Map(
                            workspaceList.map((workspace) => [
                              workspace.id,
                              true,
                            ]),
                          ),
                        );
                      } else {
                        setSelectedMembersMap(
                          new Map(
                            membersList.map((member) => [member.id, true]),
                          ),
                        );
                      }
                    }}
                    text1={"Workspaces"}
                    text2={"Members"}
                    value1={true}
                    value2={false}
                    fontSize={"0.833rem"}
                    isDisabled={userRole === "USER"}
                  />
                </CustomColumn>
                <CustomColumn>
                  <CheckBoxSelectionContainer
                    onClick={() =>
                      handleSelectAllClick(isWorkspaceToggleSelected)
                    }
                  >
                    {isSelectAllChecked ? (
                      <StyledCheckedIcon />
                    ) : (
                      <StyledUnCheckedIcon />
                    )}
                    <span>Select All</span>
                  </CheckBoxSelectionContainer>
                </CustomColumn>
              </CustomRow>
              {isWorkspaceToggleSelected ? (
                <CustomRow
                  style={{
                    gap: "0.555rem",
                    padding: "1.111rem",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  {workspaceList.length ? (
                    workspaceList.map((workspace, index) => (
                      <CustomColumn key={index}>
                        <CheckBoxSelectionContainer
                          onClick={() => {
                            if (selectedWorkspaceMap.get(workspace.id)) {
                              setSelectedWorkspaceMap(
                                (prevSelectedWorkspaceMap) => {
                                  setSelectAllChecked(false);
                                  return new Map(prevSelectedWorkspaceMap).set(
                                    workspace.id,
                                    false,
                                  );
                                },
                              );
                            } else {
                              setSelectedWorkspaceMap(
                                (prevSelectedWorkspaceMap) => {
                                  setSelectAllChecked(
                                    [
                                      ...prevSelectedWorkspaceMap.values(),
                                    ].reduce(
                                      (acc, val) => acc + (val === true),
                                      0,
                                    ) ===
                                      workspaceList.length - 1,
                                  );
                                  return new Map(prevSelectedWorkspaceMap).set(
                                    workspace.id,
                                    true,
                                  );
                                },
                              );
                            }
                          }}
                        >
                          {selectedWorkspaceMap.get(workspace.id) ? (
                            <StyledCheckedIcon />
                          ) : (
                            <StyledUnCheckedIcon />
                          )}
                          <span>{workspace.name}</span>
                        </CheckBoxSelectionContainer>
                      </CustomColumn>
                    ))
                  ) : (
                    <StyledText>No Workspaces</StyledText>
                  )}
                </CustomRow>
              ) : (
                <CustomRow
                  style={{
                    gap: "0.555rem",
                    padding: "1.111rem",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  {membersList.length ? (
                    membersList.map((member, index) => (
                      <CustomColumn key={index}>
                        <CheckBoxSelectionContainer
                          onClick={() => {
                            if (selectedMembersMap.get(member.id)) {
                              setSelectedMembersMap((prevSelectedMemberMap) => {
                                setSelectAllChecked(false);
                                return new Map(prevSelectedMemberMap).set(
                                  member.id,
                                  false,
                                );
                              });
                            } else {
                              setSelectedMembersMap((prevSelectedMemberMap) => {
                                setSelectAllChecked(
                                  [...prevSelectedMemberMap.values()].reduce(
                                    (acc, val) => acc + (val === true),
                                    0,
                                  ) ===
                                    membersList.length - 1,
                                );
                                return new Map(prevSelectedMemberMap).set(
                                  member.id,
                                  true,
                                );
                              });
                            }
                          }}
                        >
                          {selectedMembersMap.get(member.id) ? (
                            <StyledCheckedIcon />
                          ) : (
                            <StyledUnCheckedIcon />
                          )}
                          <span>{member.name}</span>
                        </CheckBoxSelectionContainer>
                      </CustomColumn>
                    ))
                  ) : (
                    <StyledText>No Members</StyledText>
                  )}
                </CustomRow>
              )}
            </>
          )}
        </CustomColumn>
        <CustomColumn
          xs={10}
          style={{
            height: "calc(100vh - 3.95rem - 5.22rem)",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            alignContent: "flex-start",
            overflow: "auto",
          }}
        >
          {isAuditorLogLoading ? (
            <StyledText>Loading...</StyledText>
          ) : auditorLogError ? (
            <StyledText>{auditorLogError}</StyledText>
          ) : (
            <>
              <CustomColumn
                style={{
                  padding: "1.111rem",
                  gap: "1.111rem",
                  display: "flex",
                  flexWrap: "wrap",
                }}
                mb={"1.111"}
              >
                <ActionChip
                  $isSelected={isAllActionsSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!isAllActionsSelected) {
                      localStorage.setItem(
                        "auditorLogsActions",
                        JSON.stringify([
                          ["Account Actions", true],
                          ["Pipelines", true],
                          ["Workspaces", true],
                          ["Connectors", true],
                          ["Applications", true],
                          ["Users & Roles", true],
                        ]),
                      );
                      setActionsMap(
                        new Map([
                          ["Account Actions", true],
                          ["Pipelines", true],
                          ["Workspaces", true],
                          ["Connectors", true],
                          ["Applications", true],
                          ["Users & Roles", true],
                        ]),
                      );
                    }
                  }}
                >
                  All
                  {isAllActionsSelected && (
                    <StyledCancelIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        localStorage.setItem(
                          "auditorLogsActions",
                          JSON.stringify([
                            ["Account Actions", false],
                            ["Pipelines", false],
                            ["Workspaces", false],
                            ["Connectors", false],
                            ["Applications", false],
                            ["Users & Roles", false],
                          ]),
                        );
                        setActionsMap(
                          new Map([
                            ["Account Actions", false],
                            ["Pipelines", false],
                            ["Workspaces", false],
                            ["Connectors", false],
                            ["Applications", false],
                            ["Users & Roles", false],
                          ]),
                        );
                      }}
                    />
                  )}
                </ActionChip>
                {[...actionsMap.keys()].map((action, index) => (
                  <ActionChip
                    $isSelected={actionsMap.get(action)}
                    key={index}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!actionsMap.get(action)) {
                        setActionsMap((prevActionsMap) => {
                          localStorage.setItem(
                            "auditorLogsActions",
                            JSON.stringify([
                              ...new Map(prevActionsMap)
                                .set(action, true)
                                .entries(),
                            ]),
                          );
                          return new Map(prevActionsMap).set(action, true);
                        });
                      }
                    }}
                  >
                    {action}
                    {actionsMap.get(action) && (
                      <StyledCancelIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          setActionsMap((prevActionsMap) => {
                            localStorage.setItem(
                              "auditorLogsActions",
                              JSON.stringify([
                                ...new Map(prevActionsMap)
                                  .set(action, false)
                                  .entries(),
                              ]),
                            );
                            return new Map(prevActionsMap).set(action, false);
                          });
                        }}
                      />
                    )}
                  </ActionChip>
                ))}
              </CustomColumn>
              {filteredAuditorLogs.length ? (
                filteredAuditorLogs.map((dayData, index) => (
                  <CustomColumn mb={"2.777"} key={index}>
                    <DayLogsContainer dayData={dayData} />
                  </CustomColumn>
                ))
              ) : (
                <CustomColumn
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "calc(100% - 5.670rem)",
                  }}
                >
                  <img
                    src={NoLogsImage}
                    alt="No Logs Icon"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain",
                    }}
                  />
                </CustomColumn>
              )}
            </>
          )}
        </CustomColumn>
      </CustomRow>
    </PageContainer>
  );
};

export default ActivityAuditor;
