const getStartAndEndTime = (dayOffset) => {
  // Get the current date in the browser's timezone
  const now = new Date();

  const endTime = Math.floor(now.getTime() / 1000);

  // Subtract the number of days ago
  now.setDate(now.getDate() - dayOffset);

  // Set the time to the start of the day (00:00:00) to get the start of the day
  now.setHours(0, 0, 0, 0);

  // Convert the date to an epoch timestamp (in milliseconds)
  const startTime = Math.floor(now.getTime() / 1000); // Convert to seconds

  return { startTime, endTime };
};

export default getStartAndEndTime;
