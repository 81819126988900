import styled from "styled-components";
import GenericModal from "../../components/common/_genericModal";
import { useEffect, useState } from "react";
import axiosAdapter from "../../utils";
import { useContext } from "react";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { env } from "../../env";
import CustomRow from "../../components/common/_customRow";
import Column from "../../components/common/_customColumn";
import { ConnectorsContext } from "../WorkSpaceConnectors/WorkSpaceConnectorsMainPage";
import {
  StyledButtonsecondary,
  StyledPrimaryButton2,
} from "../../components/common/_buttonNewOne";
import GenericMultiSelectWithSearch from "../../components/common/_genericMultiselectWithSearch";

const Text = styled.p`
  font-family: Articulat CF Medium;
  margin: 0;
`;

const CustomConnectorDeletionModal = ({
  isOpen,
  onClose,
  customConnectorName,
  customConnectorId,
}) => {
  const { showMessage } = useContext(SnackbarContext);
  const { refetchCustomConnectors } = useContext(ConnectorsContext);

  const [versionList, setVersionList] = useState([]);
  const [selectedVersions, setSelectedVersions] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const handleDeleteClick = async () => {
    const payload = {
      workspaceId: parseInt(localStorage.getItem("selectedWorkspaceId") || 0),
      customConnectorId: customConnectorId,
      ...(selectedVersions.length > 0 && {
        customConnectorVersions: selectedVersions,
      }),
    };

    try {
      setLoading(true);
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/deleteCustomConnector",
        JSON.stringify(payload),
      );
      setLoading(false);
      showMessage("Successfully deleted the Custom Connector", "success");
      refetchCustomConnectors();
      onClose();
    } catch (err) {
      console.log("Error: deleteCustomConnector", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to delete the Custom Connector");
      }
      setLoading(false);
    }
  };

  // Below useEffect is used to fetch all the versions of the customConnector
  useEffect(() => {
    const payload = {
      workspaceId: parseInt(localStorage.getItem("selectedWorkspaceId") || 0),
      customConnectorId: customConnectorId,
    };

    (async () => {
      setLoading(true);
      try {
        const getAllCustomConnectorVersionsResponse = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/getAllCustomConnectorVersions",
          JSON.stringify(payload),
        );
        setVersionList(
          getAllCustomConnectorVersionsResponse.data.data.versions,
        );
        setLoading(false);
      } catch (err) {
        console.log("Error: getAllCustomConnectorVersions", err);

        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage("Unable to fetch the Connector Versions");
        }
        setLoading(false);
      }
    })();
  }, [customConnectorId, showMessage]);

  return (
    <GenericModal
      show={isOpen}
      onClose={onClose}
      title={`Are you sure you want to delete ${customConnectorName} ?`}
    >
      <CustomRow>
        <Column style={{ marginBottom: "1.39rem" }}>
          <Text
            style={{
              opacity: 0.7,
              color: "#ECECEC",
              fontSize: "1.25rem",
              lineHeight: "1.6rem",
              letterSpacing: "0px",
            }}
          >
            {`This will delete ${customConnectorName}. If you wish to delete a particular
              version of this connector, please choose, from the dropdown below:`}
          </Text>
        </Column>
        <CustomRow
          style={{
            marginBottom: "1.39rem",
            marginTop: "1.39rem",
            width: "100%",
          }}
        >
          <Column
            xs={4.8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginBottom: "1.39rem",
            }}
          >
            <Text
              style={{
                color: "#EA3A3A",
                fontSize: "0.972rem",
                lineHeight: "1.112rem",
                fontStyle: "italic",
              }}
            >
              {selectedVersions.length > 0
                ? `This will delete the versions - ${selectedVersions.join(
                    ", ",
                  )}`
                : `This will delete the whole Connector`}
            </Text>
          </Column>
          <Column
            xs={7.1}
            style={{
              marginBottom: "1.39rem",
            }}
          >
            <GenericMultiSelectWithSearch
              placeholder="No Versions Selected"
              selectionCountLabel="Versions Selected"
              values={selectedVersions}
              options={versionList}
              nameFunction={(item) => item}
              isOptionSelectedFunction={(value) =>
                selectedVersions.includes(value)
              }
              isSearchable={true}
              searchPlaceHolder={"Search for Versions"}
              filterOptionsFunction={(arr, text) =>
                arr.filter((item) =>
                  item.toLowerCase().includes(text.toLowerCase()),
                )
              }
              onChangeFunction={(item, wasSelected) => {
                if (wasSelected) {
                  setSelectedVersions(
                    selectedVersions.filter((i) => i !== item),
                  );
                } else {
                  setSelectedVersions([...selectedVersions, item]);
                }
              }}
            />
          </Column>
        </CustomRow>
        <Column xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
          <StyledButtonsecondary
            style={{ marginRight: "1.112rem", width: "14.167rem" }}
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </StyledButtonsecondary>
          <StyledPrimaryButton2
            style={{
              width: "14.167rem",
              marginRight: "1px",
            }}
            disabled={isLoading}
            onClick={handleDeleteClick}
          >
            Delete
          </StyledPrimaryButton2>
        </Column>
      </CustomRow>
    </GenericModal>
  );
};

export default CustomConnectorDeletionModal;
