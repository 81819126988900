import styled from "styled-components";
import FlexContainer from "./_flexContainer";

const Chip = styled(FlexContainer)`
  flex: none;
  place-content: center;
  width: ${({ $width }) => $width || "2.222rem"};
  height: ${({ $height }) => $height || "2.222rem"};
  border: ${({ $border }) => $border || "0.138rem solid #0d0d0d"};
  border-radius: 100%;
  position: relative;
  background: ${({ $background }) => $background || "#2f2f2f"};
  cursor: ${({ $cursor }) => $cursor || ""};
  &:hover {
    z-index: 1;
    border: ${({ $hoverBorder, $border }) =>
      $hoverBorder || $border || "0.138rem solid #0d0d0d"};
  }
  &:not(:first-of-type) {
    left: ${({ $index }) => `${-0.486 * $index}rem`};
  }
`;

export default Chip;
