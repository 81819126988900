import React, { useContext, useMemo, useState } from "react";
import GenericModal from "../../../components/common/_genericModal";
import styled from "styled-components";
import CustomRow from "../../../components/common/_customRow";
import Column from "../../../components/common/_customColumn";
import { workspaceAvatarMap } from "../../../components/common/_workspaceAvatarMap";
import { SnackbarContext } from "../../../layouts/Context/snackBarContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
  StyledPrimaryButton2,
} from "../../../components/common/_buttonNewOne";
import axiosAdapter from "../../../utils";
import { env } from "../../../env";

// Constant Values
const MAX_CHARACTERS_DESC = 40;
const MAX_CHARACTERS_NAME = 23;

const InputFieldContainer = styled.div`
  padding: 0.972rem 0.833rem;
  width: 100%;
  border-radius: 0.555rem;
  border: 0.138rem solid #777777;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InputField = styled.input`
  font-family: "Articulat CF Medium";
  font-size: 0.972rem;
  font-weight: bold;
  line-height: 1.111rem;
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;

  &::placeholder {
    color: #b0b0b0;
    font-weight: 500;
  }
`;

const DescriptionContainer = styled.div`
  position: relative;
`;

const CharacterCount = styled.span`
  position: absolute;
  right: 0.694rem;
  bottom: 0.694rem;
  font-size: 0.972rem;
  color: #b0b0b0;
`;

const ModalTextArea = styled.textarea`
  width: 100%;
  padding: 0.694rem;
  outline: none;
  resize: none;
  border-radius: 0.555rem;
  border: 0.138rem solid #777777;
  font-family: "Articulat CF Medium";
  font-size: 0.972rem;
  font-weight: bold;
  line-height: 1.111rem;
  color: #f6f6f6;
  background-color: inherit;

  &::placeholder {
    color: #b0b0b0;
    font-weight: 500;
  }
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  color: #f6f6f6;
  font-size: 1.25rem;
  line-height: 1.555rem;
  margin: 0;
`;

const AvatarIcon = styled.div`
  opacity: ${({ $isSelected }) => ($isSelected ? 1 : 0.4)};
  cursor: "pointer";
  height: 3.888rem;
  width: 3.888rem;
`;

const getTitleText = (stage, isEdit, workspaceName) => {
  switch (stage) {
    case "INITIAL":
      return isEdit ? `Edit ${workspaceName}` : "Create a New Workspace";
    case "SUCCESS":
      return "Workspace Creation is Successful";
    case "ERROR":
      return "Workspace Creation is Unsuccessful";
    default:
      return "Create a New Workspace";
  }
};

/**
 * WorkspaceCreateEditModal
 *
 * This component renders a modal for creating or editing a workspace.
 *
 * @param {boolean} isEdit - Whether the modal is for editing an existing workspace or creating a new one.
 * @param {boolean} isOpen - Whether or not the modal is open.
 * @param {function} onClose - The function to call when the user closes the modal.
 * @param {string} oldWorkspaceName - The name of the workspace to edit.
 * @param {string} oldWorkspaceIcon - The icon of the workspace to edit.
 * @param {number} oldWorkspaceCreatedOn - The timestamp of when the workspace was created.
 * @param {string} oldWorkspaceDescription - The description of the workspace to edit.
 * @param {number} oldWorkspaceId - The ID of the workspace to edit.
 * @param {Map<string,function>} refetchWorkspacesMap - The map containing the refetch functions for each page.
 */
const WorkspaceCreateEditModal = ({
  isEdit,
  isOpen,
  onClose,
  oldWorkspaceName,
  oldWorkspaceIcon,
  oldWorkspaceCreatedOn,
  oldWorkspaceDescription,
  oldWorkspaceId,
  refetchWorkspacesMap,
}) => {
  const { showMessage } = useContext(SnackbarContext);
  const history = useHistory();

  // State Variables
  const [currentStage, setCurrentStage] = useState("INITIAL");
  const [workspaceName, setWorkspaceName] = useState(
    oldWorkspaceName ? oldWorkspaceName : "",
  );
  const [workspaceDescription, setWorkspaceDescription] = useState(
    oldWorkspaceDescription ? oldWorkspaceDescription : "",
  );
  const [selectedWorkspaceIcon, setSelectedWorkspaceIcon] = useState(
    oldWorkspaceIcon ? oldWorkspaceIcon : "Avataar_1.svg",
  );
  const [isApiLoading, setApiLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Function Declarations
  const onPrimaryButtonClick = async () => {
    setApiLoading(true);
    let tempWorkspaceName = workspaceName.trim();
    let tempWorkspaceDescription = workspaceDescription.trim();
    if (!tempWorkspaceName) {
      showMessage("Workspace name cannot be empty");
      setApiLoading(false);
      return;
    }
    if (!tempWorkspaceDescription) {
      showMessage("Workspace description cannot be empty");
      setApiLoading(false);
      return;
    }
    if (tempWorkspaceName.length > MAX_CHARACTERS_NAME) {
      showMessage(
        `Workspace name cannot be more than ${MAX_CHARACTERS_NAME} characters`,
      );
      setApiLoading(false);
      return;
    }
    if (tempWorkspaceDescription.length > MAX_CHARACTERS_DESC) {
      showMessage(
        `Workspace description cannot be more than ${MAX_CHARACTERS_DESC} characters`,
      );
      setApiLoading(false);
      return;
    }
    try {
      if (!isEdit) {
        await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "user-management/createWorkspace",
          {
            name: tempWorkspaceName,
            description: tempWorkspaceDescription,
            iconFile: selectedWorkspaceIcon,
          },
        );
      } else {
        await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "user-management/updateWorkspaceDetails",
          {
            name: tempWorkspaceName,
            description: tempWorkspaceDescription,
            iconFile: selectedWorkspaceIcon,
            workspaceId: parseInt(oldWorkspaceId),
          },
        );
      }
      if (refetchWorkspacesMap?.current) {
        refetchWorkspacesMap.current.forEach((refetch) => {
          refetch();
        });
      }
      setCurrentStage("SUCCESS");
      setApiLoading(false);
    } catch (err) {
      console.log("Error: workspace Create or Edit", err);
      if (err.response?.data?.remarks) {
        setErrorMessage(err.response.data.remarks);
      } else {
        setErrorMessage(
          isEdit
            ? "Something went wrong!! Failed to Edit Workspace"
            : "Something went wrong!! Failed to Create Workspace",
        );
      }
      setCurrentStage("ERROR");
      setApiLoading(false);
    }
  };

  // Memoized data variables
  const createdOn = useMemo(() => {
    return oldWorkspaceCreatedOn
      ? new Date(oldWorkspaceCreatedOn * 1000).toLocaleString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      : new Date().toLocaleString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
  }, [oldWorkspaceCreatedOn]);

  const titleText = useMemo(() => {
    return getTitleText(currentStage, isEdit, oldWorkspaceName);
  }, [currentStage, isEdit, oldWorkspaceName]);

  return (
    <GenericModal
      show={isOpen}
      onClose={onClose}
      title={titleText}
      disableCloseOnOutsideClick={isApiLoading}
      maxHeight="85vh"
    >
      {/* Initial Stage */}
      {currentStage === "INITIAL" && (
        <CustomRow style={{ rowGap: "1.111rem" }}>
          <Column style={{ width: "calc(50% - 0.555rem)" }}>
            <InputFieldContainer>
              <InputField
                value={workspaceName}
                onChange={(e) =>
                  e.target.value.length <= MAX_CHARACTERS_NAME &&
                  setWorkspaceName(e.target.value)
                }
                placeholder="Workspace Name"
              />
              <Text
                style={{
                  marginLeft: "0.555rem",
                  fontSize: "0.972rem",
                  lineHeight: "1.111rem",
                  color: "#B0B0B0",
                  opacity: "0.7",
                  textWrap: "nowrap",
                  overflow: "visible",
                  whiteSpace: "normal",
                  textOverflow: "clip",
                }}
              >
                {workspaceName.length}/{MAX_CHARACTERS_NAME}
              </Text>
            </InputFieldContainer>
          </Column>
          <Column style={{ width: "calc(50% - 0.555rem)" }}>
            <InputFieldContainer>
              <InputField value={"Created on " + createdOn} readOnly />
            </InputFieldContainer>
          </Column>
          <Column xs={12} style={{ marginBottom: "0.486rem" }}>
            <DescriptionContainer>
              <ModalTextArea
                placeholder="Describe Your Workspace"
                value={workspaceDescription}
                onChange={(e) =>
                  e.target.value.length <= MAX_CHARACTERS_DESC &&
                  setWorkspaceDescription(e.target.value)
                }
                rows={4}
              />
              <CharacterCount>
                {workspaceDescription.length}/{MAX_CHARACTERS_DESC}
              </CharacterCount>
            </DescriptionContainer>
          </Column>
          <Column xs={2.6} style={{ alignSelf: "flex-start" }}>
            <Text style={{ opacity: "0.7" }}>Choose a workspace Icon</Text>
          </Column>
          <Column xs={9.3} style={{ marginBottom: "2.777rem" }}>
            <CustomRow
              style={{
                display: "flex",
                gap: "1.111rem",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {Object.entries(workspaceAvatarMap).map((icon, index) => (
                <AvatarIcon
                  key={icon[0]}
                  $isSelected={selectedWorkspaceIcon === icon[0]}
                  as={icon[1]}
                  onClick={() => setSelectedWorkspaceIcon(icon[0])}
                  style={{ cursor: "pointer" }}
                />
              ))}
            </CustomRow>
          </Column>
          <Column
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <StyledButtonsecondary
              width="13.888rem"
              style={{ marginRight: "1.111rem" }}
              onClick={onClose}
            >
              Cancel
            </StyledButtonsecondary>
            <StyledButtonPrimary
              width="13.888rem"
              onClick={onPrimaryButtonClick}
              disabled={
                isApiLoading ||
                workspaceName.trim().length === 0 ||
                workspaceDescription.trim().length === 0
              }
            >
              {isEdit ? "Save Changes" : "Create Workspace"}
            </StyledButtonPrimary>
          </Column>
        </CustomRow>
      )}
      {/* Success Stage */}
      {currentStage === "SUCCESS" && (
        <>
          <CustomRow>
            <Column style={{ marginBottom: "5.555rem" }}>
              <Text
                style={{
                  opacity: "0.7",
                  color: "#ECECEC",
                  fontSize: "1.250rem",
                  lineHeight: "1.527rem",
                }}
              >
                {isEdit
                  ? "Your workspace has been successfully updated."
                  : "A new workspace has been successfully created. You can now start using the workspace, and adding connectors to your pipeline."}
              </Text>
            </Column>
            <Column
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <StyledButtonsecondary
                width="13.888rem"
                style={{ marginRight: "1.111rem" }}
                onClick={() => {
                  onClose();
                  history.push("/onBoarding");
                }}
              >
                Go to Workspace
              </StyledButtonsecondary>
              <StyledButtonPrimary width="13.888rem" onClick={onClose}>
                Done
              </StyledButtonPrimary>
            </Column>
          </CustomRow>
        </>
      )}
      {/* Error Stage */}
      {currentStage === "ERROR" && (
        <CustomRow>
          <Column style={{ marginBottom: "5.555rem" }}>
            <Text
              style={{
                opacity: "0.7",
                color: "#ECECEC",
                fontSize: "1.250rem",
                lineHeight: "1.527rem",
              }}
            >
              {errorMessage}
            </Text>
          </Column>
          <Column
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <StyledButtonsecondary
              width="13.888rem"
              style={{ marginRight: "1.111rem" }}
              onClick={onClose}
            >
              Cancel
            </StyledButtonsecondary>
            <StyledPrimaryButton2
              width="13.888rem"
              onClick={() => setCurrentStage("INITIAL")}
            >
              Try Again
            </StyledPrimaryButton2>
          </Column>
        </CustomRow>
      )}
    </GenericModal>
  );
};

export default WorkspaceCreateEditModal;
