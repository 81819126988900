import ConversionPath from "../../assets/images/cable_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import ElectricCable from "../../assets/images/electrical_services_FILL0_wght400_GRAD0_opsz24 1.svg";
import MergeIcon from "../../assets/images/merge_FILL0_wght400_GRAD0_opsz24 1.svg";
import RecentreIcon from "../../assets/images/recenter_FILL0_wght400_GRAD0_opsz24 1.svg";
import RouteIcon from "../../assets/images/route_FILL0_wght400_GRAD0_opsz24 1.svg";
import TripIcon from "../../assets/images/trip_origin_FILL0_wght400_GRAD0_opsz24 1.svg";
import ZoomIcon from "../../assets/images/zoom_in_map_FILL0_wght400_GRAD0_opsz24 1.svg";

export const applicationIconMapper = {
  "ConversionPath.svg": ConversionPath,
  "ElectricCable.svg": ElectricCable,
  "ZoomIcon.svg": ZoomIcon,
  "RecentreIcon.svg": RecentreIcon,
  "TripIcon.svg": TripIcon,
  "RouteIcon.svg": RouteIcon,
  "MergeIcon.svg": MergeIcon,
};
