import React from "react";
import Chip from "../common/_chip";
import { Fragment } from "react";
import Text from "../common/_text";
import Tooltip from "../common/_tooltip";
import Popover from "../common/_popover";
import FlexContainer from "../common/_flexContainer";

const WorkspaceCardMembers = ({ row }) => {
  const PopoverContent = row.workspaceMembers
    .slice(3)
    .map((workspaceMember, index) => (
      <Fragment key={index}>
        <Chip
          $width="1.388rem"
          $height="1.388rem"
          $background="#B0B0B0"
          $border="none"
        >
          <Text
            $fontWeight="bold"
            $fontSize="0.833rem"
            $color="#4B4B4B"
            $cursor="default"
          >
            {workspaceMember.substring(0, 1).toUpperCase()}
          </Text>
        </Chip>
        <Text $width="calc(100% - 1.388rem - 0.555rem)" $fontSize="0.833rem">
          {workspaceMember}
        </Text>
      </Fragment>
    ));

  return (
    <FlexContainer
      $width="100%"
      $justifyContent="flex-start"
      $flexWrap="nowrap"
    >
      {row.workspaceMembers.map((workspaceMember, index) =>
        index <= 3 ? (
          index < 3 ? (
            <Tooltip key={index} title={workspaceMember} placement="bottom">
              <Chip
                $index={index}
                $background="#B0B0B0"
                $border="0.138rem solid #2F2F2F"
              >
                <Text
                  $fontWeight="bold"
                  $fontSize="0.833rem"
                  $color="#4B4B4B"
                  $cursor="default"
                >
                  {workspaceMember.substring(0, 1).toUpperCase()}
                </Text>
              </Chip>
            </Tooltip>
          ) : (
            <Popover
              $maxWidth="10.416rem"
              key={index}
              content={PopoverContent}
              placement="bottom-end"
            >
              <Chip
                $index={index}
                $background="#B0B0B0"
                $border="0.138rem solid #2F2F2F"
              >
                <Text
                  $fontWeight="bold"
                  $fontSize="0.833rem"
                  $color="#4B4B4B"
                  $cursor="default"
                >
                  {`+${row.workspaceMembers.length - 3}`}
                </Text>
              </Chip>
            </Popover>
          )
        ) : null,
      )}
    </FlexContainer>
  );
};
export default WorkspaceCardMembers;
