import { useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import GenericModal from "../../../components/common/_genericModal";
import Text from "../../../components/common/_text";
import styled from "styled-components";
import CustomRow from "../../../components/common/_customRow";
import Column from "../../../components/common/_customColumn";
import {
  StyledButtonsecondary,
  StyledPrimaryButton2,
} from "../../../components/common/_buttonNewOne";
import { SnackbarContext } from "../../../layouts/Context/snackBarContext";
import axiosAdapter from "../../../utils";
import { env } from "../../../env";

const CustomListItem = styled.li`
  ::marker {
    color: #b0b0b0;
    font-size: 0.5rem;
  }
`;

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const InputField = styled.input`
  font-family: "Articulat CF Medium";
  font-size: 1.111rem;
  line-height: 1.944rem;
  font-weight: 500;
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  caret-color: white;
  &::placeholder {
    color: #777777;
  }
`;

const InputFieldContainer = styled.div`
  padding: 0.625rem 1.111rem;
  width: 100%;
  border-radius: 0.555rem;
  border: none;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.069rem solid #b0b0b0;
`;

const DeleteAccountConfirmationModal = ({ isOpen, onClose }) => {
  const { logout, username } = useContext(AuthContext);
  const { showMessage } = useContext(SnackbarContext);

  const [confirmationText, setConfirmationText] = useState("");
  const [isApiLoading, setApiLoading] = useState(false);

  const handleDeleteClick = async () => {
    setApiLoading(true);
    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/deleteAccount",
      );
      setApiLoading(false);
      logout();
    } catch (err) {
      console.log("Error: deleteAccount", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Failed to delete the account, please try again later");
      }
      setApiLoading(false);
    }
  };

  return (
    <GenericModal
      title={"Are you sure you want to delete this account?"}
      onClose={onClose}
      show={isOpen}
      disableCloseOnOutsideClick={isApiLoading}
    >
      <CustomRow>
        <CustomColumn>
          <Text $color={"#B0B0B0"} $fontSize={"1.25rem"}>
            Deleting this account will delete all of its workspaces and data.
            This cannot be undone. Please be certain about your decision.
          </Text>
        </CustomColumn>
        <CustomColumn mb={2.77}>
          <ul style={{ marginTop: "1rem" }}>
            <CustomListItem>
              <Text $color={"#B0B0B0"} $fontSize={"1.25rem"}>
                You will not be able to log into Condense without creating a new
                account.
              </Text>
            </CustomListItem>
            <CustomListItem>
              <Text $color={"#B0B0B0"} $fontSize={"1.25rem"}>
                The deleted content will not be recoverable.
              </Text>
            </CustomListItem>
          </ul>
        </CustomColumn>
        <CustomColumn mb={1.666}>
          <Text
            $fontWeight={"bold"}
            $fontSize={"1.666rem"}
            $lineHeight={1}
          >{`Type '${username}' to confirm`}</Text>
        </CustomColumn>
        <CustomColumn mb={2.777}>
          <InputFieldContainer>
            <InputField
              placeholder="Enter the above confirmation text"
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
              readOnly={isApiLoading}
            />
          </InputFieldContainer>
        </CustomColumn>
        <CustomColumn
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <StyledButtonsecondary
            width="13.888rem"
            style={{ marginRight: "1.111rem" }}
            onClick={onClose}
            disabled={isApiLoading}
          >
            Cancel
          </StyledButtonsecondary>
          <StyledPrimaryButton2
            width="13.888rem"
            onClick={() => handleDeleteClick()}
            disabled={isApiLoading || confirmationText !== username}
          >
            Delete Account
          </StyledPrimaryButton2>
        </CustomColumn>
      </CustomRow>
    </GenericModal>
  );
};

export default DeleteAccountConfirmationModal;
