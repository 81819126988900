import styled from "styled-components";
import Column from "../../../components/common/_customColumn";
import CustomRow from "../../../components/common/_customRow";
import GenericModal from "../../../components/common/_genericModal";
import {
  StyledButtonsecondary,
  StyledPrimaryButton2,
} from "../../../components/common/_buttonNewOne";
import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  color: #ececec;
  font-size: 1.25rem;
  line-height: 1.555rem;
  margin: 0;
  opacity: 0.7;
`;

export const SignOutConfirmationModal = ({ isOpen, onClose }) => {
  const { logout } = useContext(AuthContext);

  return (
    <GenericModal title={"Sign Out"} show={isOpen} onClose={onClose}>
      <CustomRow>
        <CustomColumn mb={6.25}>
          <Text>
            You will be signed out of your account. You need to enter our
            credentials to log back in.
          </Text>
        </CustomColumn>
        <CustomColumn
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <StyledButtonsecondary
            width="13.888rem"
            style={{ marginRight: "1.111rem" }}
            onClick={onClose}
          >
            Cancel
          </StyledButtonsecondary>
          <StyledPrimaryButton2
            width="13.888rem"
            onClick={() => {
              logout();
              onClose();
            }}
          >
            Sign Out
          </StyledPrimaryButton2>
        </CustomColumn>
      </CustomRow>
    </GenericModal>
  );
};
